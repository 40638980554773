import { Button, Card, Modal } from '@material-ui/core';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { connect } from 'react-redux';
// import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  setLineupFormData,
  setLineupPhotos,
  setDeletedPhotos,
} from 'reducers/LineupsSearchReducer';
import { setModalShow } from '../../../reducers/PersonSearchReducer';
const DragAndDrop = (props) => {
  const {
    open,
    persons,
    websocket,
    lineupFormData,
    setLineupFormData,

    setLineupPhotos,
    setDeletedPhotos,
    lineupFormDetails,
    lineupPhotos,
    deletedPhotos,
    modalShow,
    setModalShow,
  } = props;

  const handleClose = () => {};

  const handleOnDragEnd = (result) => {
    const items = Array.from(lineupPhotos);

    const [reorderedItem] = items.splice(result.source.index, 1);
    if (result.destination) {
      items.splice(result.destination.index, 0, reorderedItem);
      const resetOrder = items.map((item, index) => (item.OrderValue = index));
      // console.log('resetOrder', resetOrder);
      setLineupPhotos(items);
    }

    // updateCharacters(items);
  };

  return (
    <Modal
      open={modalShow}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div
        style={{
          width: '1000px',
          margin: 'auto',
          marginTop: '30px',
          backgroundColor: 'white',
          padding: '30px',
          height: '90vh',
        }}>
        <h2>Reorder</h2>
        <div
          style={{
            backgroundColor: '#eee',
            width: '70%',
            margin: 'auto',
            padding: '30px',
            height: '90%',
            textAlign: 'center',
            overflowY: 'scroll',
          }}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="lineups" direction="vertical">
              {(provided) => (
                <div
                  container
                  spacing={2}
                  style={{ width: '50%', margin: 'auto' }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {lineupPhotos.map((img, index) => {
                    return (
                      <Draggable
                        key={img.ptsImageID}
                        draggableId={'' + img.ptsImageID}
                        index={index}>
                        {(provided) => (
                          <Card
                            className="mb-2"
                            style={{ width: '400px' }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <img
                              alt="..."
                              className="card-img-top"
                              src={
                                img.uploadedImage
                                  ? img.uploadedImage
                                  : `data:image/jpeg;base64,${img.FileObject}`
                              }
                              style={{
                                objectFit: 'contain',
                                width: '250px',
                                height: '250px',
                              }}
                            />
                          </Card>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Button
          style={{ float: 'right' }}
          onClick={() => setModalShow(false)}
          color="primary"
          aria-label="Settings"
          variant="contained">
          Close
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    websocket: state.websocket,
    lineupFormData: state.LineupSearch.lineupFormData,
    lineupFormDetails: state.LineupSearch.lineupFormDetails,
    lineupPhotos: state.LineupSearch.lineupPhotos,
    deletedPhotos: state.LineupSearch.deletedPhotos,
    modalShow: state.personSearch.personForm.modalShow,
  };
};

export default connect(mapStateToProps, {
  setLineupFormData,
  setModalShow,
  setLineupPhotos,
  setDeletedPhotos,
})(DragAndDrop);
