import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router';
import { connect, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TextField, Button, Grid, Switch, Typography, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';

import {
  getCodeState,
  getCodeSex,
  getCodeRace,
  getCodeMugshotTypes,
  getCodeEyeColor,
  getCodeHanded,
  getCodeEthnicity,
  getCodeHairLength,
  getCodeHairColor,
} from '../../reducers/CodeReducer';
import {
  getInformixCodeSex,
  getInformixCodeHairColor,
  getInformixCodeEyeColor,
  getInformixCodeEthnicity,
} from '../../reducers/InformixCodesReducer';
import {
  clearPersonForm,
  handleDateChange,
  handleFilterChange,
  handleRangeFilterChange,
  handleCheckboxChange,
  setModalShow,
  setPhotoUpload,
  handleDataSourceChange,
} from '../../reducers/PersonSearchReducer';
import { clearLineupInfo, setLineupFormDetails } from 'reducers/LineupsSearchReducer';

import { DATABASE_TYPE, PTS_DATABASE } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginBottom: '1em',
  },
  filters: {
    padding: 20,
    marginBottom: 20,
  },
  filterAction2: {
    textAlign: 'right',

    position: 'absolute',

    display: 'flex',
    alignItems: 'center',
    right: 8,
    bottom: 10,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  filterActionLeft: {
    textAlign: 'right',

    position: 'absolute',

    display: 'flex',
    alignItems: 'center',
    left: 5,
    bottom: 8,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  filterAction: {
    textAlign: 'right',

    position: 'absolute',

    display: 'flex',
    alignItems: 'center',
    right: 3,
    bottom: 8,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  searchWrap: {
    height: '240px',
  },
  filter: {
    width: '100%',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  datePicker: {
    width: '100%',
    textTransform: 'uppercase',
    paddingTop: 6,
    '& input::-webkit-input-placeholder': {
      fontSize: 14,
      opacity: 0.6,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0 10px 10px 0px',
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  select: {
    width: '100%',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  selectInput: {
    fontSize: '16px',
  },
  iconButton: {
    padding: 10,
  },
  reset: {
    marginRight: 20,
  },
  searchBtnWrap: {
    display: 'inline-block',
    position: 'relative',
    paddingRight: '1em',
    right: -9,
  },
  buttonProgress: {
    color: '#B00927',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 45,
    height: 24,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#8ce8ff',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 19,
    height: 19,
    marginTop: '1px',
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 1,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const SearchForm = (props) => {
  const classes = useStyles();
  const [filters, setFilters] = useState(props.tableconfig.display);
  const { loaded } = props.persons;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const {
    getCodeSex,
    getCodeRace,
    getCodeMugshotTypes,
    clearPersonForm,
    handleDateChange,
    handleFilterChange,
    handleRangeFilterChange,
    wsClient,
    pickerValues,
    filterValues,
    searchType,
    lineupFormDetails,
    lineupFormData,
    getInformixCodeSex,
    lineupPhotos,
    deletedPhotos,
    setLineupFormDetails,
    clearLineupInfo,
    setModalShow,
    getCodeEyeColor,
    getCodeHairColor,
    getCodeHanded,
    getCodeEthnicity,
    getCodeHairLength,
    informixEthnicityCodes,
    setPhotoUpload,
    informixSexCodes,
    getInformixCodeHairColor,
    getInformixCodeEyeColor,
    informixHairColorCodes,
    informixEyeColorCodes,
    recordType,
    getInformixCodeEthnicity,
    user,
  } = props;
  const { permissions } = user;

  const codes = {
    sex: props.informixSexCodes,

    ethnicity: props.informixEthnicityCodes,
    haircolor: props.informixHairColorCodes,
    eyecolor: props.informixEyeColorCodes,
  };

  useEffect(() => {
    loaded && loading && setLoading(false);
  }, [loaded, loading]);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getInformixCodeSex();
      getCodeMugshotTypes();
      getCodeState();
      getInformixCodeEthnicity();
      getInformixCodeHairColor();
      getInformixCodeEyeColor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient]);

  useEffect(() => {
    // if (condition) {
    // }
  }, [permissions]);
  const clearForm = () => {
    clearPersonForm();
  };

  useEffect(() => {
    if (props.persons.count) {
      setLoading(true);
      props.getPersonsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = (e) => {
    e.preventDefault();

    props.getPersonsData();
    setLoading(true);
  };

  const getTextFilter = (filter, idx) => (
    <Grid item xs={2}>
      <TextField
        type="search"
        size="small"
        variant="outlined"
        label={filter.label}
        key={idx}
        style={{ textTransform: 'uppercase' }}
        className={classes.filter}
        value={filterValues[filter.dbName] ? filterValues[filter.dbName].search : ''}
        onChange={(e) => handleFilterChange(e, filter)}
      />
    </Grid>
  );

  const getRangeFilter = (filter, idx) => (
    <>
      <Grid item xs={2}>
        <TextField
          type="search"
          size="small"
          variant="outlined"
          label={'Min ' + filter.label}
          key={idx + '1'}
          className={classes.filter}
          value={filterValues[filter.dbName] ? filterValues[filter.dbName].from : ''}
          onChange={(e) => handleRangeFilterChange(e, 'from', filter)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          type="search"
          size="small"
          variant="outlined"
          label={'Max ' + filter.label}
          key={idx + '2'}
          className={classes.filter}
          value={filterValues[filter.dbName] ? filterValues[filter.dbName].to : ''}
          onChange={(e) => handleRangeFilterChange(e, 'to', filter)}
        />
      </Grid>
    </>
  );

  const getDateFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    const minToValue = value.from ? value.from : undefined;
    const maxFromValue = value.to ? value.to : new Date();
    return (
      <Fragment key={idx}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={2}>
            <KeyboardDatePicker
              className={classes.datePicker}
              clearable
              style={{ paddingTop: '0px', textTransform: 'uppercase' }}
              size="small"
              inputVariant="outlined"
              value={value.from}
              placeholder={`${filter.label} FROM`}
              onChange={(date) => handleDateChange(date, filter.dbName, 'from')}
              maxDate={maxFromValue}
              format="MM/dd/yyyy"
            />
          </Grid>
          <Grid item xs={2}>
            <KeyboardDatePicker
              size="small"
              className={classes.datePicker}
              clearable
              style={{ paddingTop: '0px' }}
              inputVariant="outlined"
              value={value.to}
              placeholder={`${filter.label} TO`}
              onChange={(date) => handleDateChange(date, filter.dbName, 'to')}
              minDate={minToValue}
              maxDate={new Date()}
              format="MM/dd/yyyy"
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  };

  const getDateSingleFilter = (filter, idx) => {
    const value = pickerValues[filter.dbName]
      ? pickerValues[filter.dbName]
      : { from: null, to: null };
    return (
      <Grid item xs={2}>
        <KeyboardDatePicker
          style={{ marginTop: 0, paddingTop: 0 }}
          key={idx}
          variant="inline"
          inputVariant="outlined"
          size="small"
          className={classes.datePicker}
          value={value.from}
          placeholder={filter.label}
          onChange={(date) => handleDateChange(date, filter.dbName, 'single')}
          format="MM/DD/yyyy"
        />
      </Grid>
    );
  };

  const getSelectFilter = (filter, idx) => (
    <Grid item xs={2}>
      <FormControl variant="outlined" size="small" className={classes.select} key={idx}>
        <InputLabel id={`filter-${filter.dbName}`}>{filter.label}</InputLabel>
        <Select
          label={filter.label}
          className={classes.selectInput}
          labelId={`filter-${filter.dbName}`}
          value={filterValues[filter.dbName] ? filterValues[filter.dbName].search : ''}
          onChange={(e) => handleFilterChange(e, filter)}>
          <MenuItem value=""> </MenuItem>

          {codes[filter.label.split(' ').join('').toLowerCase()]?.map((code, idx) => (
            <MenuItem value={code[Object.keys(code)[0]]} key={idx}>
              {code[Object.keys(code)[1]]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );

  const handleChange = (event) => {
    event.target.checked === true
      ? dispatch(handleDataSourceChange('employee'))
      : dispatch(handleDataSourceChange('inmate'));
  };

  return (
    <div className={classes.searchWrap}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <form onSubmit={submitForm} className="p-1">
          <div style={{ display: 'flex', padding: '8px 5px ' }}>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>INMATE</Grid>
                {DATABASE_TYPE !== PTS_DATABASE ? (
                  <>
                    <Grid item>
                      <AntSwitch
                        disabled={props.disapleSourceChange}
                        checked={recordType === 'employee' ? true : false}
                        onChange={handleChange}
                        name="checkedC"
                      />
                    </Grid>
                    <Grid item>EMPLOYEE</Grid>{' '}
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Typography>
          </div>

          <Grid container spacing={1} xs={12} style={{ margin: '0 10px 10px 0px' }}>
            {filters &&
              Object.values(filters).map((filter, idx) => {
                switch (filter.type) {
                  case 'date':
                    return getDateFilter(filter, idx);
                  case 'date-single':
                    return getDateSingleFilter(filter, idx);
                  case 'select':
                    return getSelectFilter(filter, idx);
                  case 'range':
                    return getRangeFilter(filter, idx);
                  default:
                    return getTextFilter(filter, idx);
                }
              })}
          </Grid>
          {/* <div className={classes.filterActionLeft}>
              <FormControl
                variant="outlined"
                disabled={searchType == 'lineup' ? true : false}
                size="small"
                style={{ width: '200px', textAlign: 'left', marginRight: '15px' }}
                key={'RecordType'}>
                <InputLabel id={`filter-RecordType`}>Record Type</InputLabel>
                <Select
                  label="Record Type"
                  className={classes.selectInput}
                  labelId={`RecordType`}
                  value={searchType == 'lineup' ? 'inmate' : recordType}
                  onChange={(e) => dispatch(handleDataSourceChange(e))}>
                  {['inmate', 'employee'].map((code, idx) => (
                    <MenuItem value={code} key={code}>
                      {code.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                disabled={!id ? false : true}
                size="small"
                style={{ width: '200px', textAlign: 'left', marginRight: '15px' }}
                key={'RecordType'}>
                <InputLabel id={`filter-SearchType`}>Search Type</InputLabel>
                <Select
                  label="Search Type"
                  className={classes.selectInput}
                  labelId={`SearchType`}
                  value={searchType}
                  onChange={(e) => dispatch(handleLineupChange(e))}>
                  {[
                    { value: 'ic', label: 'Image Capture' },
                    { value: 'lineup', label: 'Lineup' },
                  ].map((code, idx) => (
                    <MenuItem value={code.value} key={code.value}>
                      {code.label.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
          <div className={classes.filterAction}>
            {/* <FormControlLabel
                style={{ marginTop: '10px' }}
                control={
                  <Checkbox
                    disabled={isLineUp ? true : false}
                    checked={isLineUp ? true : isOffender}
                    onChange={(e) => dispatch(handleDataSourceChange(e))}
                    name="gilad"
                  />
                }
                label="Offender Search"
              /> */}
            <Button
              startIcon={<DeleteOutlineIcon />}
              onClick={clearForm}
              className={classes.reset}
              disabled={loading}>
              Clear Form
            </Button>
            <div className={classes.searchBtnWrap}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SearchIcon />}
                type="submit"
                style={{
                  width: '120px',
                }}
                disabled={loading}>
                Search
              </Button>
              {loading && (
                <CircularProgress color="primary" size={24} className={classes.buttonProgress} />
              )}
            </div>
          </div>
        </form>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  sexCodes: state.codes.sexCodes,
  user: state.user,
  hairLengthCodes: state.codes.hairLengthCodes,
  ethnicityCodes: state.codes.ethnicityCodes,
  handedCodes: state.codes.handedCodes,
  raceCodes: state.codes.raceCodes,
  hairColorCodes: state.codes.hairColorCodes,
  eyeColorCodes: state.codes.eyeColorCodes,
  statesCodes: state.codes.statesCodes,
  pickerValues: state.personSearch.personForm.pickers,
  filterValues: state.personSearch.personForm.filters,
  searchType: state.personSearch.personForm.searchType,
  recordType: state.personSearch.personForm.recordType,
  lineupFormDetails: state.LineupSearch.lineupFormDetails,
  lineupPhotos: state.LineupSearch.lineupPhotos,
  deletedPhotos: state.LineupSearch.deletedPhotos,
  lineupFormData: state.LineupSearch.lineupFormData,
  modalShow: state.personSearch.personForm.modalShow,
  photoUpload: state.personSearch.personForm.photoUpload,
  informixSexCodes: state.InformixCodes.informixSexCodes,
  informixEyeColorCodes: state.InformixCodes.informixEyeColorCodes,
  informixHairColorCodes: state.InformixCodes.informixHairColorCodes,
  informixEthnicityCodes: state.InformixCodes.informixEthnicityCodes,
});

export default connect(mapStateToProps, {
  getCodeState,
  getCodeSex,
  getCodeRace,

  getCodeMugshotTypes,
  clearPersonForm,
  handleDateChange,
  handleFilterChange,
  handleRangeFilterChange,
  handleCheckboxChange,
  setLineupFormDetails,
  clearLineupInfo,
  getInformixCodeSex,
  getInformixCodeEthnicity,
  getInformixCodeHairColor,
  getInformixCodeEyeColor,
  setModalShow,
  setPhotoUpload,
  getCodeEyeColor,
  getCodeHairColor,
  getCodeHanded,
  getCodeEthnicity,
  getCodeHairLength,
})(SearchForm);
