import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import DemoImage from '../../../assets/images/avatars/avatar-526x526.png';
import Logo from '../../../assets/images/Grnwrk.jpg';
import BoldFont from '../../../assets/widgets/Roboto/Roboto-Bold.ttf';
import RegularFont from '../../../assets/widgets/Roboto/Roboto-Regular.ttf';
import JsBarcode from 'jsbarcode';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
    },
  ],
});
const styles = StyleSheet.create({
  card: {
    width: '100%',
    textAlign: 'center',
  },
  fac: {
    fontSize: 9,
    paddingTop: 1,
    color: '#ff0000',
  },
  redText: {
    fontSize: 9,
    paddingTop: 4,
    color: '#ff0000',
  },
  greenText: {
    fontSize: 9,
    paddingTop: 4,
    color: '#0000ff',
  },
  nameText: {
    fontSize: 9,
    paddingTop: 6,
    color: '#0000ff',
    fontWeight: 800,
  },
  img: {
    width: '53px',
    height: '79px',
    textAlign: 'center',
    objectFit: 'contain',
    backgroundColor: '#eee',
  },
  header: {
    marginTop: 26,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  body: {
    textAlign: 'left',
    paddingLeft: 10,
  },
  dept: {
    fontSize: 28,
    fontWeight: 800,
    color: '#ff0000',
  },
  logoContainer: {
    width: '70px',
    height: '79px',
    backgroundColor: '#00ff00',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '59px',
    height: '58px',
  },
  secondPage: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 9,
    paddingTop: 4,
  },
  t2: {
    fontSize: 9,
    paddingTop: 6,
  },
  boldText: {
    fontSize: 9,
    paddingRight: 4,
    fontFamily: 'Roboto',
    fontWeight: 'extrabold',
  },
  boldKey: {
    fontSize: 9,
    width: 45,
    fontFamily: 'Roboto',
    fontWeight: 'extrabold',
  },
  boldvalue: {
    fontSize: 9,

    fontFamily: 'Roboto',
    fontWeight: 'extrabold',
  },
  tableView: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 18,
    paddingTop: 3,
  },
});

const EmpGrnwrkbadge = ({ personInfo, images }) => {
  let canvas;
  let front = images?.filter((img) => img.InternalCode == 'FRONT');
  let defaultImage = front?.filter((img) => img.IsDefault == true);
  if (defaultImage.length == 0) {
    defaultImage = front;
  }
  // For Barcode
  canvas = document.createElement('canvas');
  let len = personInfo.ptspersonid ? (personInfo.ptspersonid + '').length : 0;
  let zeros = '';
  for (let i = 0; i < 13 - len; i++) {
    zeros = zeros + '0';
  }
  const codeValue = zeros + (personInfo.ptspersonid || '');
  JsBarcode(canvas, codeValue, {
    format: 'ITF14',
    fontSize: 12,
    height: 75,
    textPosition: 'top',
    marginBottom: 37,
  });
  const barcode = canvas.toDataURL();
  return (
    <Document>
      <Page size="ID1">
        <View style={styles.card}>
          <View style={styles.header}>
            <Image
              style={styles.img}
              source={front[0] ? `data:image/jpeg;base64,${defaultImage[0].FileObject}` : DemoImage}
            />
            <View style={styles.logoContainer}>
              <Image style={styles.logo} source={Logo} />
            </View>
          </View>
          <View style={styles.body}>
            <Text style={styles.text}>
              <Text style={styles.boldText}>Name: </Text>
              {personInfo.lastname + ', ' + personInfo.firstname + ' ' + personInfo.middlename}
            </Text>

            <Text style={{ marginTop: 10, fontSize: 9 }}> {personInfo.worktitle || ''}</Text>

            <Text style={styles.t2}> {personInfo.facility || ''}</Text>
            <Text style={styles.t2}>
              <Text style={styles.boldText}>Date Issued: </Text>
              {new Date().toLocaleDateString()}
            </Text>
          </View>
          <Image style={{ maxHeight: 60 }} source={barcode} />
        </View>
      </Page>
      <Page size="ID1">
        <View style={{ marginTop: 27 }}>
          <View style={styles.tableView}>
            <Text style={styles.boldKey}>DOB</Text>
            <Text style={styles.boldText}>{personInfo.born}</Text>
          </View>
          <View style={styles.tableView}>
            <Text style={styles.boldKey}>Hgt</Text>
            <Text style={styles.boldText}>{personInfo.height}</Text>
          </View>
          <View style={styles.tableView}>
            <Text style={styles.boldKey}>Wgt</Text>
            <Text style={styles.boldText}>{personInfo.weight}</Text>
          </View>
          <View style={styles.tableView}>
            <Text style={styles.boldKey}>Hair</Text>
            <Text style={styles.boldText}>{personInfo.haircolor}</Text>
          </View>

          <View style={styles.tableView}>
            <Text style={styles.boldKey}>Eye</Text>
            <Text style={styles.boldText}>{personInfo.eyecolor}</Text>
          </View>
          <View style={styles.tableView}>
            <Text style={styles.boldKey}>Sex</Text>
            <Text style={styles.boldText}>{personInfo.sex_cd}</Text>
          </View>
          <View style={styles.secondPage}>
            <Text style={{ marginTop: 18, fontSize: 9, paddingHorizontal: 20 }}>
              This badge is the property of the Colorado Department of Corrections and must be
              returned to the DOC upon termination of employment.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default EmpGrnwrkbadge;
