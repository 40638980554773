import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { submitLoginForm } from '../reducers/UserReducer';

import ptsLogo from '../assets/images/PTS-logo.png';
import pic from '../assets/images/pic.png';

import splashScreen from '../assets/images/login-splash.jpg';
import { checkForUpdates } from '../reducers/AppInfoReducer';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover',
  },
  card: {
    width: 480,
    height: 510,
    maxWidth: '95%',
    background: '#fff',
    '& img': {
      width: '100%',
    },
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  branding: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#0051a0',
    '& img': {
      width: 150,
      height: 'auto',
    },
  },
  branding2: {
    width: '100%',
    textAlign: 'center',
    padding: '25px 0 5px',
    '& img': {
      height: 50,
      width: 'auto',
    },
  },
  formWrap: {
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
  actions: {
    textAlign: 'center',
    marginTop: 30,
    '& button': {
      background: '#0051a0',
      paddingLeft: '4em',
      paddingRight: '4em',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#0051c7',
      },
    },
  },
  input: {
    color: '#333 !important',
    '& input': {
      boxShadow: 'none !important',
      color: '#333 !important',
      textFillColor: '#333 !important',
    },
  },
  version: {
    position: 'absolute',
    bottom: '0.5em',
    left: '1em',
    lineHeight: 1,
  },
  name: {
    fontSize: '2em',
    color: '#FFF',
    fontWeight: '900',
  },
}));

const PageLogin = (props) => {
  const classes = useStyles();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const { appVersion } = props.appInfo;

  useEffect(() => {
    props.checkForUpdates();
    // eslint-disable-next-line
  }, []);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const submitForm = (event) => {
    event.preventDefault();
    props.submitLoginForm(username, password);
  };

  return (
    <div className={classes.pageWrap}>
      <Card className={classes.card}>
        <div className={classes.branding}>
          <img src={pic} alt="MOBILE icon" />
        </div>
        <div className={classes.branding2}>
          <img src={ptsLogo} alt="PTS logo" />
        </div>
        <CardContent className={classes.content}>
          <form onSubmit={submitForm}>
            <div className={classes.formWrap}>
              <TextField
                required
                variant="outlined"
                label="Username"
                fullWidth
                placeholder="Enter your username"
                type="text"
                value={username}
                onChange={handleUsernameChange}
                autoComplete="username"
                size="small"
                InputProps={{
                  className: classes.input,
                }}
              />
            </div>
            <div className={classes.formWrap}>
              <TextField
                required
                variant="outlined"
                label="Password"
                fullWidth
                placeholder="Enter your password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                autoComplete="current-password"
                size="small"
                InputProps={{
                  className: classes.input,
                }}
              />
            </div>
            <div className={classes.actions}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                className={classes.btn}>
                Login
              </Button>
            </div>
          </form>
          <div className={classes.version}>{appVersion}</div>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
  appInfo: state.appInfo,
});

export default connect(mapStateToProps, { submitLoginForm, checkForUpdates })(PageLogin);
