import React, { useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './Routes';
import store from './config/configureStore';
import { history } from './config/configureStore';
import ScrollToTop from './utils/ScrollToTop';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DayTheme, NightTheme } from './theme';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Notifier from 'global-components/Notifier';
import { AnimatePresence } from 'framer-motion';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Spinner from 'components/Spinner';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NotificationPanel from './components/NotificationPanel';
import Dialogs from './Dialogs';
import { notify } from './reducers/NotifierReducer';
import './assets/base.scss';
import './icons';

import { getSettings } from './reducers/ConfigReducer';
import { LicenseInfo } from '@material-ui/x-grid';
import { setPermissions } from 'reducers/PermissionCheckReducer';

LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_KEY);

const App = (props) => {
  const snackbarRef = useRef();
  const { authenticated, websocket, ui, getSettings } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { showSpinner } = ui;

  const themeMode = () => {
    let theme = DayTheme;
    if (props.mode === 'night') {
      theme = NightTheme;
    }
    return theme;
  };
  React.useEffect(() => {
    dispatch(setPermissions());
  }, [user]);
  useEffect(() => {
    if (websocket.websocket && authenticated) {
      getSettings();
    }
    // eslint-disable-next-line
  }, [websocket, authenticated]);

  const closeSnackbar = (key) => () => {
    snackbarRef.current.closeSnackbar(key);
  };

  return (
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={store.persistor}>
        <ThemeProvider theme={themeMode()}>
          <CssBaseline />
          <ScrollToTop>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider
                ref={snackbarRef}
                action={(key) => (
                  <IconButton onClick={closeSnackbar(key)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}>
                <Notifier />
                <NotificationPanel />
                <AnimatePresence>
                  <PerfectScrollbar>
                    <div style={{ height: '100vh' }}>
                      <Routes />
                    </div>
                  </PerfectScrollbar>
                </AnimatePresence>
              </SnackbarProvider>
              <Dialogs />
            </MuiPickersUtilsProvider>
          </ScrollToTop>
        </ThemeProvider>
      </PersistGate>
      {showSpinner && <Spinner />}
    </ConnectedRouter>
  );
};

const mapStateToProps = (state) => ({
  mode: state.theme.mode,
  ui: state.ui,
  websocket: state.websocket,
  authenticated: state.user.isAuthenticated,
});

export default connect(mapStateToProps, {
  getSettings,
  notify,
})(App);
