import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet, Font } from '@react-pdf/renderer';

import BoldFont from '../../../assets/widgets/Arial/ARIALBD.ttf';
import RegularFont from '../../../assets/widgets/Arial/ARIAL.ttf';
import MediumFont from '../../../assets/widgets/Arial/ArialMdm.ttf';
import offenderImage from '../../../assets/images/WristbadgeImage.png';
import moment from 'moment';
// import moment from 'moment';
Font.register({
  family: 'Arial',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
      fontWeight: 'bold',
    },
    {
      src: MediumFont,
    },
  ],
});
const styles = StyleSheet.create({
  leftText: {
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 'bold',
    width: '30%',
  },
  rightText: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 'bold',
    width: '60%',
    paddingLeft: 22,
  },
  redText: {
    fontSize: 19,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#ff0000',
    paddingTop: 6,
  },

  offImg: {
    width: 237,
    height: 353,
    objectFit: 'contain',
  },
  leftText2: {
    textAlign: 'right',
    fontSize: 16,
    fontWeight: 'bold',
    width: '50%',
  },
  rightText2: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 'bold',
    width: '50%',
    paddingLeft: 22,
  },
});

// Create Document Component
const FRPRViewBadge = ({ personInfo, images }) => {
  let front = images?.filter((img) => img.InternalCode == 'FRONT');
  let defaultFront = front?.filter((img) => img.IsDefault == true);
  if (defaultFront.length == 0) {
    defaultFront = front;
  }
  let profile = images?.filter((img) => img.InternalCode == 'PROFILE');
  let defaultProfile = profile?.filter((img) => img.IsDefault == true);
  if (defaultProfile.length == 0) {
    defaultProfile = profile;
  }
  return (
    <Document>
      <Page
        size="LETTER"
        orientation="landscape"
        style={{ paddingHorizontal: 50, paddingVertical: 35, fontFamily: 'Arial' }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Image
            style={styles.offImg}
            source={
              defaultFront[0]
                ? `data:image/jpeg;base64,${defaultFront[0].FileObject}`
                : offenderImage
            }
          />
          <Image
            style={styles.offImg}
            source={
              defaultProfile[0]
                ? `data:image/jpeg;base64,${defaultProfile[0].FileObject}`
                : offenderImage
            }
          />
        </View>
        <View style={{ marginTop: 90, display: 'flex', flexDirection: 'row', width: '100%' }}>
          <View style={{ width: '60%' }}>
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
              <Text style={styles.leftText}>Name: </Text>
              <Text style={styles.rightText}>
                {personInfo.lastname + ', ' + personInfo.firstname}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
              <Text style={styles.leftText}>Birth Dt: </Text>
              <Text style={styles.rightText}> {moment(personInfo.born).format('YYYY-MM-DD')} </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.leftText}>DOC Inmate #:</Text>

              <Text style={styles.rightText}> {personInfo.ptspersonid}</Text>
            </View>
          </View>
          <View style={{ width: '40%' }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                marginTop: 'auto',
              }}>
              <Text style={styles.leftText2}>Photo Date:</Text>

              <Text style={styles.rightText2}>
                {moment(defaultFront[0]?.Created || defaultProfile[0]?.Created).format(
                  'YYYY/MM/DD'
                )}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FRPRViewBadge;
