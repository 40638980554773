import React from 'react';
// import ReactPDF from '@react-pdf/renderer';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
// import moment from 'moment';
// import { PDFViewer } from '@react-pdf/renderer';
// import { Button } from '@material-ui/core';
// Create styles
import DemoImage from '../../../assets/images/avatars/avatar-526x526.png';
import Logo from '../../../assets/images/CIRT.png';
import BoldFont from '../../../assets/widgets/Arial/ARIALBD.ttf';
import RegularFont from '../../../assets/widgets/Arial/ARIAL.ttf';
import MediumFont from '../../../assets/widgets/Arial/ArialMdm.ttf';
Font.register({
  family: 'Arial',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
      fontWeight: 'bold',
    },
    {
      src: MediumFont,
    },
  ],
});
const styles = StyleSheet.create({
  card: {
    width: '100%',
    textAlign: 'center',
  },
  fac: {
    marginTop: 30,
    fontSize: 9,
    paddingTop: 1,
    color: '#ff0000',
  },
  redText: {
    fontSize: 9,
    paddingTop: 4,
    color: '#ff0000',
  },
  greenText: {
    fontSize: 9,
    paddingTop: 4,
    color: '#0000ff',
  },
  nameText: {
    fontSize: 9,
    paddingTop: 6,
    color: '#0000ff',
    fontWeight: 'bold',
    fontFamily: 'Arial',
    textTransform: 'uppercase',
  },
  img: {
    width: '53px',
    height: '79px',
    margin: 'auto',
    marginTop: 3,
    textAlign: 'center',
    objectFit: 'contain',
  },
  footer: {
    paddingTop: 8,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  dept: {
    fontSize: 28,
    fontWeight: 'bold',
    fontFamily: 'Arial',

    color: '#ff0000',
  },
  logo: {
    width: '59px',
    height: '58px',
  },
  secondPage: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    paddingHorizontal: 20,
  },
  t2: {
    fontSize: 9,
  },
});

// Create Document Component
const EmpCntbadge = ({ personInfo, images }) => {
  let front = images?.filter((img) => img.InternalCode == 'FRONT');
  let defaultImage = front?.filter((img) => img.IsDefault == true);
  if (defaultImage.length == 0) {
    defaultImage = front;
  }
  return (
    <Document>
      <Page size="ID1">
        <View style={styles.card}>
          <Text style={styles.fac}>{personInfo.facility}</Text>
          <Text style={styles.redText}>Crisis Negotiation Team</Text>
          <Text style={styles.greenText}>Colorado Dept. of Corrections</Text>
          <Image
            style={styles.img}
            source={front[0] ? `data:image/jpeg;base64,${defaultImage[0].FileObject}` : DemoImage}
          />
          <Text style={styles.nameText}>
            {personInfo.lastname + ', ' + personInfo.firstname + ' ' + personInfo.middlename}
          </Text>
          <View style={styles.footer}>
            <Text style={styles.dept}>CNT</Text>
            <Image style={styles.logo} source={Logo} />
          </View>
        </View>

        {/* <View style={styles.view}>
          {imageData?.map((img, index) => (
            <View style={styles.view2} wrap={false}>
              <Image style={styles.img} source={img.uploadedImage} />
            </View>
          ))}
        </View> */}
      </Page>
      <Page size="ID1">
        <View style={styles.secondPage}>
          <Text style={styles.t2}>
            This badge is the property of the Colorado Department of Corrections and must be
            returned to the DOC upon termination of employment.
          </Text>
        </View>

        {/* <View style={styles.view}>
          {imageData?.map((img, index) => (
            <View style={styles.view2} wrap={false}>
              <Image style={styles.img} source={img.uploadedImage} />
            </View>
          ))}
        </View> */}
      </Page>
    </Document>
  );
};

export default EmpCntbadge;
