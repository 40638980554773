import {
  Button,
  Card,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BlobProvider } from '@react-pdf/renderer';
import EmpDayBadge from 'components/RecordPdf/EmployeePdf/EmpDayBadge';
import EmpDrdcescort from 'components/RecordPdf/EmployeePdf/EmpDrdcescortbadge';
import EmpDrdcnoescort from 'components/RecordPdf/EmployeePdf/EmpDrdcnoescortbadge';
const useStyles = makeStyles((theme) => ({
  pageWrap: {
    display: 'flex',
    minHeight: '100%',
    padding: theme.spacing(2),
    '& > div': {
      heihgt: '100%',
      '&:first-child': {
        width: '55%',
      },
      '&:nth-child(2)': {
        width: '45%',
      },
    },
    '& h3': {
      fontSize: '1.25rem',
    },
  },
  eventList: {
    width: '30%',
  },
  actionButton: {
    float: 'right',
    position: 'absolute',
    right: '10px',
    bottom: '10px',
  },
  eventsWrap: {
    display: 'flex',
  },
  btnGroup: {
    marginBottom: 10,
    marginLeft: 10,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  eventDetails: {
    width: '70%',
    boxSizing: 'border-box',
    padding: '0 10px 0 10px',
  },
  input: {
    width: '100%',
  },
  root: {
    height: window.innerHeight - 255 + 'px',
  },
  button: {
    width: '130px',
  },

  units: {
    paddingLeft: '1em',
  },
  error: {
    '&.MuiFormHelperText-root.Mui-error': {
      color: theme.palette.common.white,
    },
  },
}));
const types = ['emp-day0', 'emp-drdcescort', 'emp-drtcnoescort'];
const ReportsPDF = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ badgeType: types[0] });
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState({});
  const handleInputChange = (e) => {
    const newForm = { ...formData };
    if (e.target.name == 'from' || e.target.name == 'to') {
      if (!Number(e.target.value)) {
        setErrorText({ ...errorText, [e.target.name]: 'Input should be integer' });
      } else {
        const error = { ...errorText };
        delete error[e.target.name];
        setErrorText(error);
      }

      newForm[e.target.name] = Number(e.target.value) || e.target.value;
    } else {
      newForm[e.target.name] = e.target.value;
    }
    // newForm[e.target.name] = e.target.value || e.target.select;
    setFormData(newForm);
  };
  const handleClearForm = (data) => {
    setFormData({ badgeType: types[0], from: '', to: '' });
    setErrorText({});
  };
  const handleGenerate = (e) => {};
  return (
    <div>
      <Card className="card-box m-3" style={{ height: '150px', padding: '10px' }}>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              style={{ width: '100%' }}
              size="small"
              className={classes.select}
              id="select">
              <InputLabel id="types">Type</InputLabel>
              <Select
                label="Type"
                labelId="badgeType"
                name="badgeType"
                value={formData.badgeType}
                onChange={handleInputChange}>
                <MenuItem value=""> </MenuItem>

                {types.map((code) => (
                  <MenuItem value={code} key={code} className={classes.selectInput}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              variant="outlined"
              label="From"
              key="Form"
              name="from"
              className={classes.input}
              value={formData.from}
              helperText={errorText['from']}
              FormHelperTextProps={{
                style: { color: 'red' },
              }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              variant="outlined"
              label="To"
              key="To"
              name="to"
              className={classes.input}
              value={formData.to}
              helperText={errorText['to']}
              FormHelperTextProps={{
                style: { color: 'red' },
              }}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <div className={classes.actionButton}>
          <Button className={classes.button} onClick={handleClearForm}>
            Clear Form
          </Button>
          {/* {selected.selected && (
            <>
              <IconButton color="secondary" aria-label="add an alarm">
                <RemoveCircleIcon fontSize="large" />
              </IconButton>
              <IconButton color="primary" aria-label="add to shopping cart">
                <EditIcon fontSize="large" />
              </IconButton>
            </>
          )} */}
          <BlobProvider
            document={
              formData.badgeType == 'emp-day0' ? (
                <EmpDayBadge formData={formData} />
              ) : formData.badgeType == 'emp-drdcescort' ? (
                <EmpDrdcescort formData={formData} />
              ) : formData.badgeType == 'emp-drtcnoescort' ? (
                <EmpDrdcnoescort formData={formData} />
              ) : null
            }
            fileName="lineup.pdf">
            {({ url }) => (
              <a
                href={url}
                target="_blank"
                style={{
                  width: '125px',
                  textAlign: 'center',
                  backgroundColor: '#0153a3',
                  color: 'whiteSmoke',
                  padding: '8px',
                  borderRadius: '5px',
                  marginRight: '15px',
                }}>
                Create Report
              </a>
            )}
          </BlobProvider>
        </div>
      </Card>
      <Card className="card-box m-3">
        <Grid container className={classes.root}>
          <React.Fragment className={classes.paper}></React.Fragment>
        </Grid>
      </Card>
    </div>
  );
};

export default ReportsPDF;
