import store from '../config/configureStore';
import { getSettings, clearSettings } from './ConfigReducer';
import { clearEvents } from './EventsReducer';
import { clearUnits } from './UnitsReducer';
//import { updateZones } from './ZonesReducer';
import { notify } from './NotifierReducer';

export const USER_SET_AUTHENTICATED = 'USER/SET_AUTHENTICATED';
export const USER_SET_DATA = 'USER/SET_DATA';
export const USER_SET_USER_UNIT = 'USER/USER_SET_USER_UNIT';
export const USER_SET_USER_UNITS = 'USER/SET_USER_UNITS';
export const USER_CLEAR_DATA = 'USER/USER_CLEAR_DATA';
export const USER_SET_PERMISSION = 'USER/USER_SET_PERMISSION';
let logOutSent = false;

export const submitLoginForm = (username, password) => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const user = await client.authenticate({
        type: 'pic',
        strategy: 'local',
        username: username,
        password: password,
      });
      logOutSent = false;
      dispatch({ type: USER_SET_DATA, payload: user });
      dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
      dispatch(getSettings());
      //dispatch(updateZones());
    } catch (error) {
      const { code } = error;
      switch (code) {
        case 408:
          dispatch(
            notify(
              'It takes too long to connect to the server. Please check your connection and try again.',
              'warning'
            )
          );
          break;
        case 401:
          dispatch(notify('Login failed. Please try again.', 'error'));
          break;
        default:
          dispatch(
            notify(`Unknown error ${code}. Please check you connection and try again.`, 'error')
          );
      }
    }
  };
};

export const setUserAuthenticated = (state) => {
  const client = store.store.getState().websocket.websocket;
  return (dispatch) => {
    if (client) {
      if (state === false) {
        dispatch(logOut());
      } else {
        dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
      }
    }
  };
};

export const logOut = () => (dispatch) => {
  if (logOutSent) return;
  logOutSent = true;
  try {
    const client = store.store.getState().websocket.websocket;
    client && client.logout();
    dispatch({ type: USER_CLEAR_DATA });
    dispatch(clearSettings());
    dispatch(clearEvents());
    dispatch(clearUnits());
  } catch (err) {
    console.log(err);
  }

  // dispatch(setUserData(null));
};

export const setUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_SET_DATA, payload: data });
  };
};

// ----------------------- units

export const setUserUnits = (units) => {
  return (dispatch) => {
    dispatch({ type: USER_SET_USER_UNITS, payload: units });
  };
};

export const setUserUnit = (unit) => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    if (unit) {
      dispatch({ type: USER_SET_USER_UNIT, payload: unit });
    } else {
      if (!client) return;
      try {
        const unitID = store.store.getState().user.userUnit.ptsUnitID;
        const unit = await client.service('units').get(unitID);
        dispatch({ type: USER_SET_USER_UNIT, payload: unit[0] });
      } catch (error) {
        console.log('UserReducer/setUserUnit: error: ', error, error.code);
      }
    }
  };
};

const defaultState = {
  isAuthenticated: false,
  userData: null,
  permissions: [],
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USER_SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case USER_SET_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case USER_CLEAR_DATA:
      return defaultState;
    case USER_SET_PERMISSION:
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            Permissions: JSON.stringify(action.payload),
          },
        },
      };
    default:
      break;
  }
  return state;
}
