const ADD_PAGE = 'PAGING/ADD_PAGE';
const UNIT_STATUS = 'UNIT_STATUS';
const ADD_EVENT = 'ADD_EVENT';
const EDIT_EVENT = 'EDIT_EVENT';
const ADD_SOP = 'ADD_SOP';
const EDIT_SOP = 'EDIT_SOP';
const EDIT_PAGE_GROUP = 'EDIT_PAGE_GROUP';
const EDIT_PAGE_RECIPIENT = 'EDIT_PAGE_RECIPIENT';
const EDIT_PAGE_TEMPLATE = 'EDIT_PAGE_TEMPLATE';
const IMPORT_GEOFENCES = 'IMPORT_GEOFENCES';
const DISPATCH_RECOMMENDATIONS = 'DISPATCH_RECOMMENDATIONS';
const UNIT_PROPS = 'UNIT_PROPS';
const ADD_NOTE = 'ADD_NOTE';
const ITEM_DETAIL = 'ITEM_DETAIL';
const NEW_UNIT_STATUS = 'NEW_UNIT_STATUS';
const UNIT_ZONE = 'UNIT_ZONE';
const OPEN_SETTINGS = 'OPEN_SETTINGS';
const CLOSE_SETTINGS = 'CLOSE_SETTINGS';
const DISPLAY_DATA = 'DISPLAY_DATA';
const SOPS = 'SOPS';
const PERSON = 'PERSON';
const ADD_PIC_USER = 'ADD_PIC_USER';
const EDIT_PIC_USER = 'EDIT_PIC_USER';

const defaultDialogs = {
  editPageGroup: null,
  editPageRecipient: null,
  editPageTemplate: null,
  importZones: null,
  addEvent: null,
  editEvent: null,
  addOutserviceUnit: null,
  sops: null,
  addSop: null,
  editSop: null,
  dispRecommendation: null,
  unitProps: null,
  addNote: null,
  itemDetail: null,
  newUnitStatus: null,
  unitZone: null,
  settings: null,
  displayData: null,
  addPerson: null,
  addPicUser: null,
  editPicUser: null,
};

export const SET_ADDOUTSERVICEUNIT = 'SET_ADDOUTSERVICEUNIT';

export const addOutserviceUnitDialog = (show = true) => (dispatch) =>
  dispatch({ type: SET_ADDOUTSERVICEUNIT, show });

export const addPageDialog = (data) => (dispatch) =>
  dispatch({ type: ADD_PAGE, data: data ? data : {} });
export const closeAddPageDialog = () => (dispatch) => dispatch({ type: ADD_PAGE, data: null });

export const addEvent = (data = true) => (dispatch) => dispatch({ type: ADD_EVENT, data });
export const closeAddEvent = () => (dispatch) => dispatch({ type: ADD_EVENT, data: null });

export const editEvent = (ptsEventID) => (dispatch) =>
  dispatch({ type: EDIT_EVENT, data: ptsEventID });
export const closeEditEvent = () => (dispatch) => dispatch({ type: EDIT_EVENT, data: null });

export const showSops = (ptsEventID) => (dispatch) => dispatch({ type: SOPS, ptsEventID });
export const hideSops = () => (dispatch) => dispatch({ type: SOPS, ptsEventID: null });

export const addSop = () => (dispatch) => dispatch({ type: ADD_SOP, data: true });
export const closeAddSop = () => (dispatch) => dispatch({ type: ADD_SOP, data: null });

export const editSop = (data) => (dispatch) => dispatch({ type: EDIT_SOP, data });
export const closeEditSop = () => (dispatch) => dispatch({ type: EDIT_SOP, data: null });

export const addPerson = (data = true) => (dispatch) => dispatch({ type: PERSON, data });
export const closeAddPerson = () => (dispatch) => dispatch({ type: PERSON, data: null });

export const editPageGroup = (data) => (dispatch) => dispatch({ type: EDIT_PAGE_GROUP, data });
export const closeEditPageGroup = () => (dispatch) =>
  dispatch({ type: EDIT_PAGE_GROUP, data: null });

export const editPageRecipient = (data) => (dispatch) =>
  dispatch({ type: EDIT_PAGE_RECIPIENT, data });
export const closeEditPageRecipient = () => (dispatch) =>
  dispatch({ type: EDIT_PAGE_RECIPIENT, data: null });

export const editPageTemplate = (ROWGUID) => (dispatch) =>
  dispatch({ type: EDIT_PAGE_TEMPLATE, data: ROWGUID });
export const closeEditPageTemplate = () => (dispatch) =>
  dispatch({ type: EDIT_PAGE_TEMPLATE, data: null });

export const importGeofences = (data) => (dispatch) => dispatch({ type: IMPORT_GEOFENCES, data });
export const closeImportGeofences = () => (dispatch) =>
  dispatch({ type: IMPORT_GEOFENCES, data: null });

export const dispRecommendations = (ptsEventID) => (dispatch) =>
  dispatch({ type: DISPATCH_RECOMMENDATIONS, ptsEventID });
export const closeDispRecommendations = () => (dispatch) =>
  dispatch({ type: DISPATCH_RECOMMENDATIONS, ptsEventID: null });

export const showUnitProps = (ptsUnitID) => (dispatch) => dispatch({ type: UNIT_PROPS, ptsUnitID });
export const closeUnitProps = () => (dispatch) => dispatch({ type: UNIT_PROPS, ptsUnitID: null });

export const showAddNoteDialog = (ptsEventID) => (dispatch) =>
  dispatch({ type: ADD_NOTE, ptsEventID });
export const closeAddNoteDialog = () => (dispatch) =>
  dispatch({ type: ADD_NOTE, ptsEventID: null });

export const showDisplayDataWindow = (data) => (dispatch) =>
  dispatch({ type: DISPLAY_DATA, data });
export const closeDisplayDataWindow = () => (dispatch) =>
  dispatch({ type: DISPLAY_DATA, data: null });

export const showItemDetails = (ptsItemID) => (dispatch) =>
  dispatch({ type: ITEM_DETAIL, ptsItemID });
export const closeItemDetails = () => (dispatch) =>
  dispatch({ type: ITEM_DETAIL, ptsItemID: null });

export const newUnitStatus = (ptsUnitID, ptsEventID = null) => (dispatch) =>
  dispatch({
    type: NEW_UNIT_STATUS,
    payload: { ptsUnitID, ptsEventID, title: 'New Unit Status', edit: false },
  });
export const closeNewUnitStatus = () => (dispatch) =>
  dispatch({ type: NEW_UNIT_STATUS, payload: null });

export const editUnitStatus = (ptsUnitID, ptsEventID = null) => (dispatch) =>
  dispatch({
    type: NEW_UNIT_STATUS,
    payload: { ptsUnitID, ptsEventID, title: 'Edit Unit Status', edit: true },
  });
export const closeEditUnitStatus = () => (dispatch) =>
  dispatch({ type: NEW_UNIT_STATUS, payload: null });

export const setUnitZone = (ptsUnitID) => (dispatch) => dispatch({ type: UNIT_ZONE, ptsUnitID });
export const closeSetUnitZone = () => (dispatch) => dispatch({ type: UNIT_ZONE, ptsUnitID: null });

export const openSettings = () => (dispatch) => dispatch({ type: OPEN_SETTINGS });
export const closeSettings = () => (dispatch) => dispatch({ type: CLOSE_SETTINGS });

export const showAddPicUser = (data = {}) => (dispatch) => dispatch({ type: ADD_PIC_USER, data });
export const closeAddPicUser = () => (dispatch) => dispatch({ type: ADD_PIC_USER, data: null });

export const showEditPicUser = (data = {}) => (dispatch) => dispatch({ type: EDIT_PIC_USER, data });
export const closeEditPicUser = () => (dispatch) => dispatch({ type: EDIT_PIC_USER, data: null });

export default function reducer(state = defaultDialogs, action) {
  switch (action.type) {
    case SET_ADDOUTSERVICEUNIT:
      state = { ...state, addOutserviceUnit: action.show };
      break;
    case ADD_PAGE:
      return { ...state, addPage: action.data };
    case UNIT_STATUS:
      return { ...state, unitStatus: action.data };
    case ADD_EVENT:
      return { ...state, addEvent: action.data };
    case EDIT_EVENT:
      return { ...state, editEvent: action.data };
    case SOPS:
      return { ...state, sops: action.ptsEventID };
    case ADD_SOP:
      return { ...state, editSop: action.data };
    case EDIT_SOP:
      return { ...state, editSop: action.data };
    case EDIT_PAGE_GROUP:
      return { ...state, editPageGroup: action.data };
    case EDIT_PAGE_RECIPIENT:
      return { ...state, editPageRecipient: action.data };
    case EDIT_PAGE_TEMPLATE:
      return { ...state, editPageTemplate: action.data };
    case IMPORT_GEOFENCES:
      return { ...state, importZones: action.data };
    case DISPATCH_RECOMMENDATIONS:
      return { ...state, dispRecommendation: action.ptsEventID };
    case UNIT_PROPS:
      return { ...state, unitProps: action.ptsUnitID };
    case ADD_NOTE:
      return { ...state, addNote: action.ptsEventID };
    case NEW_UNIT_STATUS:
      return { ...state, newUnitStatus: action.payload };
    case UNIT_ZONE:
      return { ...state, unitZone: action.ptsUnitID };
    case OPEN_SETTINGS:
      return { ...state, settings: true };
    case CLOSE_SETTINGS:
      return { ...state, settings: null };
    case DISPLAY_DATA:
      return { ...state, displayData: action.data };
    case PERSON:
      return { ...state, addPerson: action.data };
    case ADD_PIC_USER: 
      return { ...state, addPicUser: action.data };
    case EDIT_PIC_USER: 
      return { ...state, editPicUser: action.data };
    default:
      break;
  }
  return state;
}
