import React from 'react';
import Content from './Content';

function AdminPage() {
  return (
    <Content></Content>
  );
}

export default AdminPage;
