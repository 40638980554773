import React from 'react';

import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';

import Logo from '../../../assets/images/RangeBadge1.png';
import Logo2 from '../../../assets/images/RangeBadge2.png';
import BoldFont from '../../../assets/widgets/TimesNewRoman/times-new-roman-bold.ttf';
import RegularFont from '../../../assets/widgets/TimesNewRoman/times-new-roman.ttf';
// import italic from '../../../assets/widgets/TimesNewRoman/times-new-roman-italic.ttf';
// import boldItalic from '../../../assets/widgets/TimesNewRoman/times-new-roman-bold-italic.ttf';
Font.register({
  family: 'TimesNewRoman',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
    },
    // {
    //   src: italic,
    // },
    // {
    //   src: boldItalic,
    //   fontWeight: 'bold',
    // },
  ],
});
const styles = StyleSheet.create({
  card: {
    height: '100%',
    width: '100%',
    textAlign: 'center',
    border: ' 5px solid #32cd32',
  },

  logo: {
    width: 121,
    height: 122,
    marginRight: 2,
  },
  logo2: {
    width: 120,
    height: 118,
    marginLeft: 2,
  },

  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  number: {
    fontSize: 85,
    lineHeight: 80,
    width: '100%',
    textAlign: 'center',
    fontFamily: 'TimesNewRoman',
    fontWeight: 'normal',
    position: 'absolute',
    top: '33px',
    paddingLeft: 15,
  },
  top: {
    position: 'absolute',
    fontFamily: 'Times-Italic',
    fontWeight: 'semibold',
    paddingLeft: 3,

    fontSize: 28,
    opacity: 0.9,
  },
  bottom: {
    position: 'absolute',
    bottom: '10px',
    fontFamily: 'Times-Italic',
    fontWeight: 'semibold',
    width: '100%',
    textAlign: 'center',
    fontSize: 20,
    opacity: 0.9,
  },
});

// Create Document Component
const EmpDrdcnoescort = ({ formData }) => {
  const number = [];
  for (let i = formData.from || 1; i <= (formData.to || 1); i++) {
    number.push(i);
  }

  return (
    <Document>
      {number.map((i) => (
        <Page size="ID1" orientation="landscape" wrap={false}>
          <View style={styles.card} wrap={false}>
            <View style={styles.content}>
              <Image style={styles.logo} source={Logo} />

              <Image style={styles.logo2} source={Logo2} />
            </View>
            <Text style={styles.top}>DRDC</Text>

            <Text style={styles.number}>{i}</Text>
            <Text style={styles.bottom}>NO ESCORT REQUIRED</Text>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default EmpDrdcnoescort;
