import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ThemeReducer from './ThemeReducer';
import WebsocketReducer from './WebsocketReducer';
import NotifierReducer from './NotifierReducer';
import UiReducer from './UiReducer';
import UserReducer from './UserReducer';
import AppInfoReducer from './AppInfoReducer';
import EventsReducer from './EventsReducer';
import UnitsReducer from './UnitsReducer';
import ConfigReducer from './ConfigReducer';
import EventUnitsReducer from './EventUnitsReducer';
import UnitStatusReducer from './UnitStatusReducer';
import MapReducer from './MapReducer';
import ZonesReducer from './ZonesReducer';
import PagingReducer from './PagingReducer';
import DialogsReducer from './DialogsReducer';
import DataChangeReducer from './DataChangeReducer';
import E911Reducer from './E911Reducer';
import CodeReducer from './CodeReducer';
import InformixCodesReducer from './InformixCodesReducer';
import PersonSearchReducer from './PersonSearchReducer';
import LineupsSearchReducer from './LineupsSearchReducer';
import LineupPersonSearchReducer from './LineupPersonSearchReducer';
import PermissionCheckReducer from './PermissionCheckReducer';
import { reducer as notifications } from 'react-notification-system-redux';
import MenuReducer from './MenuReducer';
import DataUpdateReducer from './DataUpdateReducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    theme: ThemeReducer,
    websocket: WebsocketReducer,
    notifier: NotifierReducer,
    notifications: notifications,
    ui: UiReducer,
    user: UserReducer,
    events: EventsReducer,
    units: UnitsReducer,
    appInfo: AppInfoReducer,
    config: ConfigReducer,
    eventUnits: EventUnitsReducer,
    unitStatus: UnitStatusReducer,
    map: MapReducer,
    zones: ZonesReducer,
    paging: PagingReducer,
    dialogs: DialogsReducer,
    dataChange: DataChangeReducer,
    e911: E911Reducer,
    codes: CodeReducer,
    personSearch: PersonSearchReducer,
    lineupPersonSearch: LineupPersonSearchReducer,
    menu: MenuReducer,
    dataUpdate: DataUpdateReducer,

    LineupSearch: LineupsSearchReducer,
    InformixCodes: InformixCodesReducer,
    Permissions: PermissionCheckReducer,
  });

export default createRootReducer;
