import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import DemoImage from '../../../assets/images/avatars/avatar-526x526.png';
// import Logo from '../../../assets/images/Grnwrk.jpg';
import JsBarcode from 'jsbarcode';

import BoldFont from '../../../assets/widgets/TimesNewRoman/times-new-roman-bold.ttf';
import RegularFont from '../../../assets/widgets/TimesNewRoman/times-new-roman.ttf';
import moment from 'moment';

Font.register({
  family: 'TimesNewRoman',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
    },
  ],
});
const styles = StyleSheet.create({
  card: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
  },

  redText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#ff0000',
    textAlign: 'center',
  },
  greenText: {
    fontSize: 9,
    paddingTop: 4,
    color: '#0000ff',
  },
  text: {
    fontSize: 12,
    fontFamily: 'TimesNewRoman',
    fontWeight: 'bold',
    paddingBottom: 2,
  },
  nameText: {
    fontFamily: 'TimesNewRoman',
    fontSize: 11,
    textTransform: 'uppercase',
    color: '#00008b',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingRight: 2,
    flexWrap: 'nowrap',
  },
  img: {
    width: 91,
    height: 137,
    objectFit: 'contain',

    textAlign: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
  },
  key: {
    fontSize: 8,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  value: {
    fontFamily: 'TimesNewRoman',
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    paddingLeft: 12,
  },
  body: {
    width: '60%',
    paddingLeft: 4,
  },
  left: {
    width: '40%',
  },
});

const MainBadge = ({ personInfo, images }) => {
  let canvas;
  let front = images?.filter((img) => img.InternalCode == 'FRONT');
  let defaultImage = front?.filter((img) => img.IsDefault == true);
  if (defaultImage.length == 0) {
    defaultImage = front;
  }
  // For Barcode
  canvas = document.createElement('canvas');
  // let len = personInfo.ptspersonid ? (personInfo.ptspersonid + '').length : 0;
  // let zeros = '';
  // for (let i = 0; i < 5 - len; i++) {
  //   zeros = zeros + '0';
  // }
  // const codeValue = zeros + (personInfo.ptspersonid || '');
  // console.log('personInfo.ptspersonid', personInfo.ptspersonid)
  JsBarcode(canvas, personInfo.ptspersonid, {
    format: 'ITF',
    fontSize: 12,
    height: 75,
    textPosition: 'top',
    marginBottom: 37,
  });
  const barcode = canvas.toDataURL();
  return (
    <Document>
      <Page
        size="ID1"
        orientation="landscape"
        style={{ paddingHorizontal: 7, paddingTop: 7, fontFamily: 'TimesNewRoman' }}>
        <View style={styles.card}>
          <View style={styles.left}>
            <Image
              style={styles.img}
              source={front[0] ? `data:image/jpeg;base64,${defaultImage[0].FileObject}` : DemoImage}
            />
          </View>
          <View style={styles.body}>
            <Text style={styles.text}> Colorado</Text>
            <Text style={styles.text}>Dept. of Corrections</Text>

            <Text style={styles.redText}> Inmate Identification</Text>
            <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 6 }}>
              <Text style={styles.key}>Name: </Text>
              <View style={{ paddingLeft: 4 }}>
                <Text style={styles.nameText}>{personInfo.lastname + ','}</Text>
                <Text style={styles.nameText}>{personInfo.firstname}</Text>
              </View>
            </View>

            <View style={styles.info}>
              <Text style={styles.key}>DOC Inmate#:</Text>
              <Text style={styles.value}>{personInfo.ptspersonid}</Text>
            </View>
            <View style={styles.info}>
              <Text style={styles.key}>Date Of Birth:</Text>
              <Text style={styles.value}>{moment(personInfo.born).format('YYYY-MM-DD')}</Text>
            </View>
            <View style={styles.info}>
              <Text style={styles.key}>Date of Issue</Text>
              <Text style={styles.value}>{moment(new Date()).format('YYYY-MM-DD')}</Text>
            </View>
            <Image style={{ maxHeight: 30 }} source={barcode} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MainBadge;
