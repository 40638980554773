import React from 'react';
import { Page, Text, Image, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

// import { PDFViewer } from '@react-pdf/renderer';
// import { Button } from '@material-ui/core';
// Create styles
import BoldFont from '../../../assets/widgets/TimesNewRoman/times-new-roman-bold.ttf';
import RegularFont from '../../../assets/widgets/TimesNewRoman/times-new-roman.ttf';
import moment from 'moment';
Font.register({
  family: 'TimesNewRoman',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    width: '100%',
  },
  title: {
    fontSize: 11,
    fontFamily: 'TimesNewRoman',
    fontWeight: 'bold',
  },
  view: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },

  view2: {
    width: 180,

    backgroundColor: '#eee',
    marginTop: 10,
    padding: 3,
    margin: 5,
  },
  img: {
    height: '150px',
    objectFit: 'contain',
  },
  description: {
    display: 'flex',
    flexDirection: 'Column',
    padding: 10,
    backgroundColor: '#eee',
  },
  text: {
    fontSize: 10,
    paddingTop: 3,
    fontWeight: 'normal',
  },
  text2: {
    fontWeight: 'normal',
    color: '#333',
  },
  text3: {
    fontSize: 7,
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
  },
  description2: {
    paddingVertical: 3,
    backgroundColor: '#eee',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    justifyContent: 'space-between',
  },
});
const InmateReport = ({ personInfo, images }) => {
  console.log(personInfo, images);
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.description}>
          <Text style={styles.title}>INMATE ROCORD</Text>
          <Text style={styles.text}>
            ID:<Text style={styles.text2}> {personInfo?.ptspersonid} </Text>
          </Text>
          <Text style={styles.text}>
            NAME :{' ' + ' '}
            <Text style={styles.text2}>
              {personInfo.lastname +
                ', ' +
                (personInfo.firstname || '') +
                ' ' +
                (personInfo.middlename || '')}
            </Text>
          </Text>
          <Text style={styles.text}>
            ETHNICITY/ SEX :{' ' + ' '}
            <Text style={styles.text2}>
              {personInfo.ethnicity ? personInfo?.ethnicity + '/' : ''}
              {personInfo?.sex}
            </Text>
          </Text>
          <Text style={styles.text}>
            DOB :{' ' + ' '}
            <Text style={styles.text2}> {personInfo?.born}</Text>
          </Text>
        </View>

        <View style={styles.view}>
          {images?.map((img, index) => (
            <View style={styles.view2} wrap={false}>
              <Image
                style={styles.img}
                source={
                  img.uploadedImage
                    ? '' + img.uploadedImage
                    : `data:image/jpeg;base64,${img.FileObject}`
                }
              />
              <View style={styles.description2}>
                <View style={styles.info}>
                  <Text style={styles.text3}>
                    DATE TAKEN: {' ' + ' '}
                    {img?.Created ? moment(new Date(img.Created)).format('MM/DD/YYYY') : ''}
                  </Text>
                </View>

                <View style={styles.info}>
                  <Text style={styles.text3}>MARK LOCATION: {img?.Location}</Text>
                  <Text style={styles.text3}>MARK TYPE : {img?.MarkType}</Text>
                </View>

                <Text style={styles.text3}>Description : {img?.Description}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default InmateReport;
