import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import DemoImage from '../../../assets/images/avatars/avatar-526x526.png';

import BoldFont from '../../../assets/widgets/TimesNewRoman/times-new-roman-bold.ttf';
import RegularFont from '../../../assets/widgets/TimesNewRoman/times-new-roman.ttf';
import moment from 'moment';
Font.register({
  family: 'TimesNewRoman',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
    },
  ],
});
const styles = StyleSheet.create({
  redText: {
    fontSize: 12,
    fontFamily: 'TimesNewRoman',
    fontWeight: 'bold',
    color: '#ff0000',
    textAlign: 'center',
  },

  key: {
    fontSize: 10,
    fontFamily: 'TimesNewRoman',
    fontWeight: 'bold',
  },
  img: {
    width: 67,
    height: 100,
    objectFit: 'contain',
  },

  dept: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 'bold',
    color: '#ff0000',
    fontFamily: 'TimesNewRoman',

    paddingHorizontal: 12,
  },

  //   secondPage: {
  //     textAlign: 'center',
  //     display: 'flex',
  //     flexDirection: 'row',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     paddingHorizontal: 20,
  //   },
  t2: {
    height: 121,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '15px',
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  left: {
    flex: 0.35,
  },
  right: {
    flex: 0.65,
    paddingLeft: 37,
  },
  object1: {
    display: 'flex',
    paddingTop: 6,
  },
  object2: {
    display: 'flex',
    paddingTop: 15,
  },
  text: {
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'TimesNewRoman',
    fontWeight: 'bold',
  },
});

// Create Document Component
const GatepassBadge = ({ personInfo, images }) => {
  let front = images?.filter((img) => img.InternalCode == 'FRONT');
  let defaultImage = front?.filter((img) => img.IsDefault == true);
  if (defaultImage.length == 0) {
    defaultImage = front;
  }
  return (
    <Document>
      <Page size="ID1" orientation="landscape">
        <View style={styles.main}>
          <View style={styles.left}>
            <Image
              style={styles.img}
              source={front[0] ? `data:image/jpeg;base64,${defaultImage[0].FileObject}` : DemoImage}
            />
          </View>
          <View style={styles.right}>
            <Text style={styles.text}> Colorado</Text>
            <Text style={styles.text}>Dept. of Corrections</Text>

            <Text style={styles.redText}> Inmate Identification</Text>
            <Text style={styles.object1}>
              <Text style={styles.key}>Name: </Text>
              <Text style={{ color: '#0000cd', fontSize: 10, textTransform: 'uppercase' }}>
                {personInfo.lastname + ', ' + personInfo.firstname}
              </Text>
            </Text>
            <Text style={styles.object2}>
              <Text style={styles.key}>DOC Inmate #: </Text>
              <Text style={{ color: '#ff0000', fontSize: 10 }}>{personInfo.ptspersonid}</Text>
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.dept}>Gate Pass</Text>
        </View>
      </Page>
      <Page size="ID1" orientation="landscape">
        <View style={{ paddingHorizontal: 12 }}>
          <View style={styles.t2}>
            <Text style={{ fontSize: 48, fontFamily: 'TimesNewRoman', fontWeight: 'bold' }}>
              Gate Pass
            </Text>
          </View>

          <Text
            style={{
              fontSize: 12,
              textAlign: 'left',
              fontFamily: 'TimesNewRoman',
              fontWeight: 'bold',
            }}>
            DOB: {moment(personInfo.born).format('YYYY-MM-DD')}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default GatepassBadge;
