import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';

const useStyles = makeStyles((theme) => ({
  clock: {
    color: theme.colors.text1,
    fontSize: '25px',
    background: theme.colors.grey5,
    padding: '0 25px',
    borderRadius: '5px',
  },
}));

const Clock = () => {
  const classes = useStyles();
  const [dateTime, setDateTime] = useState(new Date().toLocaleString());
  const [time, setTime] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDateTime(new Date().toLocaleString());
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    for (let i = 0; i < dateTime.length; i++) {
      if (dateTime[i] === ',') {
        setTime(dateTime.substring(i + 1));
        break;
      }
    }
  }, [dateTime]);

  return (
    <div className={classes.clock}>
      {time ? <>{time}</> : <AccessTimeOutlinedIcon fontSize="large" />}
    </div>
  );
};

export default Clock;
