import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import DemoImage from '../../../assets/images/avatars/avatar-526x526.png';
import Logo from '../../../assets/images/Retiree.png';
// import Logo from '../../../assets/images/Retiree.png';
import Logo2 from '../../../assets/images/Retiree2.png';
import BoldFont from '../../../assets/widgets/Roboto/Roboto-Bold.ttf';
import RegularFont from '../../../assets/widgets/Roboto/Roboto-Regular.ttf';
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
    },
  ],
});
const styles = StyleSheet.create({
  card: {
    width: '100%',
    textAlign: 'center',
  },
  fac: {
    marginTop: 30,
    fontSize: 9,
    paddingTop: 1,
    color: '#ff0000',
  },
  redText: {
    fontSize: 9,
    paddingTop: 4,
    color: '#ff0000',
  },
  greenText: {
    fontSize: 9,
    paddingTop: 4,
    color: '#0000ff',
  },
  nameText: {
    fontSize: 10,
    paddingRight: 2,
  },
  img: {
    width: 67,
    height: 100,
    margin: 'auto',
    marginTop: 3,
    textAlign: 'center',
    objectFit: 'contain',
  },
  footer: {
    paddingTop: 8,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  dept: {
    fontSize: '20px',
    fontWeight: 'heavy',
    color: '#ff0000',
    fontFamily: 'Roboto',
    paddingBottom: 3,
  },
  logo: {
    width: 39,
    height: 44,
  },
  logo2: {
    width: 45,
    height: 44,
    marginLeft: 10,
  },
  secondPage: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    paddingHorizontal: 20,
  },
  t2: {
    fontSize: 20,
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '15px',
  },
  left: {
    flex: 0.55,
    textAlign: 'center',
  },
  right: {
    flex: 0.45,
  },
  text: {
    textAlign: 'center',
    paddingTop: 0,
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
});

// Create Document Component
const EmpRetireebadge = ({ personInfo, images }) => {
  let front = images?.filter((img) => img.InternalCode == 'FRONT');
  let defaultImage = front?.filter((img) => img.IsDefault == true);
  if (defaultImage.length == 0) {
    defaultImage = front;
  }
  return (
    <Document>
      <Page size="ID1" orientation="landscape">
        <View style={styles.main}>
          <View style={styles.left}>
            <Text style={styles.dept}>RETIRED</Text>
            <Text style={styles.text}> Colorado</Text>
            <Text style={styles.text}> Department of</Text>

            <Text style={styles.text}> Corrections</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '10px',
              }}>
              <Image style={styles.logo} source={Logo2} />
              <Image style={styles.logo2} source={Logo} />
            </View>
          </View>
          <View style={styles.right}>
            <Text style={styles.nameText}>
              {personInfo.lastname + ', ' + personInfo.firstname + ' ' + personInfo.middlename}
            </Text>
            <Image
              style={styles.img}
              source={
                front[0] ? `data:image/jpeg;base64,${defaultImage[0].FileObject}` : DemoImage
              }
            />
          </View>
        </View>
      </Page>
      <Page size="ID1" orientation="landscape">
        <View style={styles.secondPage}>
          <Text style={styles.t2}>
            This ID card is not to be used for entry into prison facilities
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default EmpRetireebadge;
