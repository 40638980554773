import React from 'react';
import clsx from 'clsx';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Button,
  Hidden,
  Avatar,
  Box,
  Badge,
  Menu,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  setNavigationDrawerMobileState,
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState,
} from '../../reducers/UiReducer';
import { setMode } from '../../reducers/ThemeReducer';
import { setUserAuthenticated } from '../../reducers/UserReducer';
import menuItems from '../../config/configureMenu';

const drawerHeight = 80;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftMenu: {
    display: 'flex',
    flex: 1,
  },
  rightMenu: {
    alignSelf: 'center',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.bgElements,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements,
  },
  btn: {
    width: '85px',
    height: '60px',
    border: '3px solid rgba(1, 83, 163, 0.5) !important',
  },
  btnLabel: {
    position: 'absolute',
    bottom: -3,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
}));

const UserBadge = withStyles({
  badge: {
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

const TopBar = (props) => {
  const {
    setNavigationDrawerMobileState,
    setMode,
    setUserAuthenticated,
    network,
    user,
    themeMode,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();
  const {
    inmatesAccess,
    inmatesCreateLineups,
    employeeAccess,
    employeeCreateLineups,
  } = useSelector((state) => state.Permissions);

  const StyledBadge = withStyles({
    badge: {
      backgroundColor: network.online === false ? 'var(--danger)' : 'var(--success)',
      color: network.online === false ? 'var(--danger)' : 'var(--success)',
    },
  })(UserBadge);

  const handleUserMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderUserMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot">
                <Avatar sizes="44" alt={user.userData ? user.userData.user.Username : null} />
              </StyledBadge>
            </Box>
            <div>
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {user.userData ? user.userData.user.Username : null}
              </div>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem button>Settings</ListItem>
            <ListItem onClick={() => setMode()} button>
              {themeMode === 'day' ? 'Night mode' : 'Day mode'}
            </ListItem>
            <Divider className="w-100" />
            <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Button
                onClick={() => setUserAuthenticated(false)}
                variant="outlined"
                size="medium"
                color="default">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                </span>
                <span className="btn-wrapper--label">Logout</span>
              </Button>
            </ListItem>
          </List>
        </div>
      </Menu>
    );
  };

  const renderMobileMenu = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <div style={{ alignSelf: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={() => setNavigationDrawerMobileState()}>
            <MenuIcon color="primary" />
          </IconButton>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ alignSelf: 'center' }}>
            <Button
              color="inherit"
              onClick={handleUserMenuClick}
              className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
              <Box>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot">
                  <Avatar sizes="44" alt={user.userData ? user.userData.user.Username : null} />
                </StyledBadge>
              </Box>
              <div className="d-none d-xl-block">
                <div className="font-weight-bold pt-2 line-height-1">
                  {user.userData ? user.userData.user.Username : null}
                </div>
              </div>
              <span className="pl-1 pl-xl-3">
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderDesktopMenu = () => {
    return (
      <div className={classes.wrapper}>
        <div className={classes.leftMenu}>
          {menuItems.map((btn, idx) => {
            if (btn.label == 'LINEUPS') {
              if (!(inmatesCreateLineups && inmatesAccess)) {
                return;
              }
            }
            return (
              <Tooltip title={btn.tooltip} key={idx}>
                <Link to={btn.link}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={clsx(classes.btn, 'p-0 m-2 d-block')}>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={btn.icon} className="font-size-xxl" />
                    </span>
                    <span className={classes.btnLabel}>{btn.label}</span>
                  </Button>
                </Link>
              </Tooltip>
            );
          })}
        </div>

        <div className={classes.rightMenu}>
          <Button
            color="inherit"
            onClick={handleUserMenuClick}
            className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
            <Box>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot">
                <Avatar sizes="44" alt={user.userData ? user.userData.user.Username : null} />
              </StyledBadge>
            </Box>
            <div className="d-none d-xl-block">
              <div className="font-weight-bold pt-2 line-height-1">
                {user.userData ? user.userData.user.Username : null}
              </div>
            </div>
            <span className="pl-1 pl-xl-3">
              <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
            </span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <AppBar color="primary" position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Hidden smDown>{renderDesktopMenu()}</Hidden>
        <Hidden mdUp>{renderMobileMenu()}</Hidden>
        {renderUserMenu()}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  network: state.offline,
  themeMode: state.theme.mode,
  user: state.user,
});

export default connect(mapStateToProps, {
  setNavigationDrawerMobileState,
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState,
  setMode,
  setUserAuthenticated,
})(TopBar);
