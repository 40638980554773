import { enqueueSnackbar } from '../reducers/NotifierReducer';
import store from '../config/configureStore';
import tableConfig from '../config/table-config-persons.json';

import { showSpinner, hideSpinner } from './UiReducer';

// Person Search
export const FIND_PERSONS = 'PERSONSEARCH/FIND';
export const LOADING_PERSONS = 'PERSONSEARCH/LOADING';
export const LOADING_PERSONS_ERROR = 'PERSONSEARCH/LOADING_ERROR';
export const SET_MODAL_SHOW = 'SET_MODAL_SHOW';
export const SET_UPLOAD_PHOTO = 'SET_UPLOAD_PHOTO';
export const FIND_PERSON = 'PERSONSEARCH/FIND_PERSON';
export const OPEN_DETAILS = 'PERSONSEARCH/OPEN_DETAILS';
export const CLOSE_DETAILS = 'PERSONSEARCH/CLOSE_DETAILS';
export const SET_FROM_DETAILS = 'SET_FROM_DETAILS';
export const CLEAR_RESULTS = 'PERSONSEARCH/CLEAR_RESULTS';
export const SET_FORM_PERSON_DETAILS_FOR_RECORDS = 'SET_FORM_PERSON_DETAILS_FOR_RECORDS';
export const SET_FORM_PERSON_PICKERS = 'NCIC/SET_FORM_PERSON_PICKERS';
export const SET_FORM_PERSON_FILTERS = 'NCIC/SET_FORM_PERSON_FILTERS';
export const SET_FORM_PERSON_CHECKBOX = 'PERSONSEARCH/SET_FORM_PERSON_CHECKBOX';
export const SET_FORM_PERSON_LINEUP = 'SET_FORM_PERSON_LINEUP';
export const SET_FORM_PERSON_EMPLOYEE = 'SET_FORM_PERSON_EMPLOYEE';
export const SET_NCIC_FINISHED_RESPONSES = 'NCIC/SET_NCIC_FINISHED_RESPONSES';
export const SET_NCIC_PENDING_RESPONSES = 'NCIC/SET_NCIC_PENDING_RESPONSES';
export const SET_PERSON_PAGINATION = 'SET_PERSON_PAGINATION';

const imageCaptureApi = process.env.REACT_APP_IMAGE_CAPTURE_API;

export const findPersons = (params) => {
  return async (dispatch) => {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    try {
      const service = client.service(imageCaptureApi);
      service.timeout = 20000;
      dispatch({ type: LOADING_PERSONS, payload: true });
      const persons = await service.find({ query: params });
      dispatch({ type: FIND_PERSONS, payload: { params, persons } });
      dispatch({ type: LOADING_PERSONS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_PERSONS_ERROR });
      dispatch(
        enqueueSnackbar({
          message: 'No data found.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          },
        })
      );
    } finally {
      dispatch({ type: LOADING_PERSONS, payload: false });
    }
  };
};

export const sortPersons = (orderBy) => {
  return async (dispatch) => {
    const changeDirection = (direction) => (direction === 'ASC' ? 'DESC' : 'ASC');
    const persons = store.store.getState().personSearch.persons;
    const { filters, pageNo, rowsPerPage } = persons;
    const orderDirection =
      persons.orderBy === orderBy ? changeDirection(persons.orderDirection) : 'ASC';
    const params = { filters, pageNo, orderBy, rowsPerPage, orderDirection };
    dispatch(findPersons(params));
  };
};

export const findPerson = (ptsPersonID, recordType) => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  return async (dispatch) => {
    if (ptsPersonID === null) {
      dispatch({
        type: CLOSE_DETAILS,
        payload: null,
      });
    } else {
      dispatch(showSpinner());
      try {
        const service = client.service(imageCaptureApi);
        service.timeout = 20000;
        const row = await service.get(ptsPersonID, { query: { recordType: recordType } });
        dispatch({ type: FIND_PERSON, payload: row });
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            message: 'No data found.',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            },
          })
        );
      }
      dispatch(hideSpinner());
    }
  };
};

export const setPersonDataForRecords = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_FORM_PERSON_DETAILS_FOR_RECORDS, payload: data });
  };
};
/* New ncic implementation */

export const handleDateChange = (date, dbName, param) => {
  const pickerValues = store.store.getState().personSearch.personForm.pickers;
  return async (dispatch) => {
    const value = pickerValues[dbName] ? pickerValues[dbName] : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = new Date();
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[dbName] = value;
    } else {
      delete currentPickerValue[dbName];
    }
    dispatch({
      type: SET_FORM_PERSON_PICKERS,
      payload: currentPickerValue,
    });
  };
};

export const handleFilterChange = (ev, filter) => {
  const values = store.store.getState().personSearch.personForm.filters;
  return async (dispatch) => {
    values[filter.dbName] = { type: filter.type, search: ev.target.value.toUpperCase() };
    dispatch({
      type: SET_FORM_PERSON_FILTERS,
      payload: cleanFilters(values),
    });
  };
};
export const handleRangeFilterChange = (ev, name, filter) => {
  const values = store.store.getState().personSearch.personForm.filters;

  return async (dispatch) => {
    if (name == 'from') {
      values[filter.dbName] = {
        ...values[filter.dbName],
        type: filter.type,
        from: ev.target.value,
      };
    } else {
      values[filter.dbName] = {
        ...values[filter.dbName],
        type: filter.type,
        to: ev.target.value,
      };
    }

    dispatch({
      type: SET_FORM_PERSON_FILTERS,
      payload: cleanFiltersForRange(values),
    });
  };
};
export const handleCheckboxChange = (ev) => {
  return async (dispatch) => {
    dispatch({
      type: SET_FORM_PERSON_CHECKBOX,
      payload: ev.target.checked,
    });
  };
};
export const handleLineupChange = (ev) => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_RESULTS,
      payload: null,
    });
    dispatch({
      type: SET_FORM_PERSON_LINEUP,
      payload: ev.target ? ev.target.value : ev,
    });
  };
};
export const handleDataSourceChange = (ev) => {
  return async (dispatch) => {
    const value = store.store.getState().personSearch.personForm.recordType;

    if (value != ev) {
      dispatch({
        type: CLEAR_RESULTS,
        payload: null,
      });
    }

    dispatch({
      type: SET_FORM_PERSON_EMPLOYEE,
      payload: ev.target ? ev.target.value : ev,
    });
  };
};
export const setFromDetail = (ev) => {
  return async (dispatch) => {
    dispatch({
      type: SET_FROM_DETAILS,
      payload: ev,
    });
  };
};
export const setPersorFiltersvalue = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_FORM_PERSON_FILTERS,
      payload: value,
    });
  };
};

export const clearPersonForm = () => {
  return async (dispatch) => {
    dispatch({
      type: SET_FORM_PERSON_FILTERS,
      payload: {},
    });
    dispatch({
      type: SET_FORM_PERSON_PICKERS,
      payload: {},
    });
  };
};

export const clearResults = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_RESULTS,
      payload: null,
    });
  };
};

export const savePhoto = (data) => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('ic-person-photo');
      service.timeout = 20000;

      const result = await service.create(data);

      return result;
    } catch (e) {
      console.log(e);
    }
  };
};

export const updatePhoto = (data) => {
  const state = store.store.getState();
  const client = state.websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('ic-person-photo');
      service.timeout = 20000;

      const result = await service.patch(data.ptsImageID, data);

      return result;
    } catch (e) {
      console.log(e);
    }
  };
};
const cleanFilters = (obj) => {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.search !== '') result[key] = val;
  });
  return result;
};
const cleanFiltersForRange = (obj) => {
  const result = {};

  Object.entries(obj).forEach(([key, val]) => {
    if (val.from !== '') result[key] = val;
    if (val.to !== '') result[key] = val;
  });

  return result;
};

export const setPersonPagination = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_PERSON_PAGINATION, payload: data });
  };
};
export const setModalShow = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_MODAL_SHOW, payload: data });
  };
};
export const setPhotoUpload = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_UPLOAD_PHOTO, payload: data });
  };
};
export default function reducer(
  state = {
    // Person
    persons: {
      rows: [],
      count: 0,
      pageNo: 0,
      search: '',
      rowsPerPage: tableConfig.rowsPerPage,
      orderBy: tableConfig.orderBy,
      orderDirection: tableConfig.orderDirection,
      loading: false,
      loaded: false,
      error: false,
      fromDetails: false,
    },
    personForm: {
      filters: {},
      pickers: {},
      ncic: false,

      searchType: 'ic',
      recordType: 'inmate',
    },
    personDetails: {
      data: null,
      ptsPersonID: null,
      isShowing: false,
      modalShow: false,
      photoUpload: false,
    },
    personDetailsForRecords: {
      data: {},
    },
  },
  action
) {
  switch (action.type) {
    // Person
    case LOADING_PERSONS:
      return {
        ...state,
        persons: {
          ...state.persons,
          loading: action.payload,
          loaded: action.payload ? false : state.persons.loaded,
          error: false,
        },
      };
    case SET_PERSON_PAGINATION:
      return {
        ...state,
        person: action.payload,
      };
    case LOADING_PERSONS_ERROR:
      return {
        ...state,
        persons: {
          ...state.persons,
          loading: false,
          loaded: true,
          error: true,
        },
      };
    case FIND_PERSONS:
      return {
        ...state,
        persons: {
          rows: action.payload.persons.data,
          ...action.payload.params,
          count: action.payload.persons.count,
          loaded: true,
          error: false,
        },
      };
    case FIND_PERSON:
      return {
        ...state,
        personDetails: { ...state.personDetails, data: action.payload },
      };
    case OPEN_DETAILS:
      return {
        ...state,
        personDetails: { ...state.personDetails, isShowing: true },
      };
    case CLOSE_DETAILS:
      return {
        ...state,
        personDetails: { ...state.personDetails, isShowing: false },
      };
    case CLEAR_RESULTS:
      return {
        ...state,
        persons: {
          rows: [],
          count: 10,
          pageNo: 0,
          search: '',
          rowsPerPage: tableConfig.rowsPerPage,
          orderBy: tableConfig.orderBy,
          orderDirection: tableConfig.orderDirection,
          loading: false,
          loaded: false,
          error: false,
        },
      };
    case SET_FORM_PERSON_PICKERS:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          pickers: action.payload,
        },
      };
    case SET_FORM_PERSON_FILTERS:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          filters: { ...action.payload },
        },
      };
    case SET_FORM_PERSON_CHECKBOX:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          ncic: action.payload,
        },
      };
    case SET_FORM_PERSON_LINEUP:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          searchType: action.payload,
        },
      };
    case SET_FROM_DETAILS:
      return {
        ...state,
        persons: {
          ...state.persons,
          fromDetails: action.payload,
        },
      };
    case SET_FORM_PERSON_EMPLOYEE: {
      return {
        ...state,
        personForm: {
          ...state.personForm,
          recordType: action.payload,
        },
      };
    }
    case SET_FORM_PERSON_DETAILS_FOR_RECORDS: {
      console.log('reducer', action.payload);
      return {
        ...state,
        personDetailsForRecords: {
          data: action.payload,
        },
      };
    }
    case SET_MODAL_SHOW:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          modalShow: action.payload,
        },
      };
    case SET_UPLOAD_PHOTO:
      return {
        ...state,
        personForm: {
          ...state.personForm,
          photoUpload: action.payload,
        },
      };
    default:
      break;
  }
  return state;
}
