import React from 'react';

import { Page, Text, View, Document, Image, StyleSheet, Font } from '@react-pdf/renderer';

import BoldFont from '../../../assets/widgets/Arial/ARIALBD.ttf';
import RegularFont from '../../../assets/widgets/Arial/ARIAL.ttf';
import MediumFont from '../../../assets/widgets/Arial/ArialMdm.ttf';
import img from '../../../assets/images/WristbadgeImage.png';
// import moment from 'moment';
Font.register({
  family: 'Arial',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
      fontWeight: 'bold',
    },
    {
      src: MediumFont,
    },
  ],
});
const styles = StyleSheet.create({
  card: {
    width: 258,
    height: '100%',
  },
  img: {
    width: 58,
    objectFit: 'contain',

    height: 138,
  },
  text: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Arial',
    paddingBottom: 7,
    color: '#ff0000',
  },
  text2: {
    fontSize: 9,
    fontWeight: 'bold',
    fontFamily: 'Arial',
    paddingBottom: 7,
  },
});

// Create Document Component
const WristBandbadge = ({ personInfo, images }) => {
  let front = images?.filter((img) => img.InternalCode == 'FRONT');
  let defaultImage = front?.filter((img) => img.IsDefault == true);
  if (defaultImage.length == 0) {
    defaultImage = front;
  }
  return (
    <Document>
      <Page size="C7" style={{ marginTop: 15 }}>
        <View style={styles.card}>
          <Image
            style={styles.img}
            source={front[0] ? `data:image/jpeg;base64,${defaultImage[0].FileObject}` : img}
          />
          <View
            style={{
              transform: 'rotate(90)',
              marginTop: 215,
              marginLeft: -160,
              textAlign: 'left',
            }}>
            <Text style={[styles.text, { textTransform: 'uppercase' }]}>
              Name: {personInfo.lastname + ', ' + personInfo.firstname}
            </Text>
            <Text style={styles.text2}>DOC: {personInfo.ptspersonid}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default WristBandbadge;
