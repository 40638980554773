const ZONES_UPDATED = 'ZONES_UPDATED';

const defaultData = {
  zones: 0,
};

let zones = 0;
let zonesChangedTimeout = 0;

export const zonesChanged = () => async (dispatch) => {
  clearTimeout(zonesChangedTimeout);
  zonesChangedTimeout = setTimeout(() => {
    dispatch({ type: ZONES_UPDATED, payload: ++zones });
  }, 1000);
};

export default function reducer(state = defaultData, action) {
  switch (action.type) {
    case ZONES_UPDATED:
      return { ...state, zones: action.payload };
    default:
      break;
  }
  return state;
}
