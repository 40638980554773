/**
 * Menu item configurations for desktop and mobile
 */

const menuSettings = [
  {
    label: 'SEARCH',
    tooltip: 'Search',
    link: '/home',
    icon: 'headset',
  },
  {
    label: 'LINEUPS',
    tooltip: 'Lineup search page',
    link: '/lineup-search',
    icon: 'headset',
  },

  {
    label: 'REPORTS',
    tooltip: 'Generate Reports',
    link: '/report-pdf',
    icon: 'headset',
  },
  {
    label: 'ADMINISTRATION',
    tooltip: 'Administration',
    link: '/administration',
    icon: 'headset',
  },
];

export default menuSettings;
