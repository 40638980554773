import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';
import { XGrid } from '@material-ui/x-grid';
import { getCodeMugshotTypes } from 'reducers/CodeReducer';
import { setCircularLoading } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
// import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles((theme) => ({
  pageWrap: {
    display: 'flex',
    minHeight: '100%',
    padding: theme.spacing(2),
    '& > div': {
      heihgt: '100%',
      '&:first-child': {
        width: '55%',
      },
      '&:nth-child(2)': {
        width: '45%',
      },
    },
    '& h3': {
      fontSize: '1.25rem',
    },
  },
  eventList: {
    width: '30%',
  },
  eventsWrap: {
    display: 'flex',
  },
  btnGroup: {
    marginBottom: 10,
    marginLeft: 10,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  eventDetails: {
    width: '70%',
    boxSizing: 'border-box',
    padding: '0 10px 0 10px',
  },
  input: {
    width: '100%',
  },
  root: {
    height: window.innerHeight - 247 + 'px',
  },
  button: {
    width: '130px',
  },
  select: {
    width: '100%',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  selectInput: {
    fontSize: '16px',
  },
  actionButton: {
    float: 'right',
    position: 'absolute',
    right: '10px',
    bottom: '10px',
  },
  units: {
    paddingLeft: '1em',
  },
}));
const fields = ['Code', 'Description', 'Category', 'InternalCode', 'Action'];

function Categories(props) {
  const classes = useStyles();
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [selected, setSelected] = useState({ data: {}, selected: false });
  const [formData, setFormData] = useState({ Category: 'IMGT' });
  const dispatch = useDispatch();
  const { mugshotTypes, wsClient } = props;

  const setXGridData = (result) => {
    if (result.length > 0) {
      const newData = result.map((rs) => {
        const obj = {
          ...rs,
          id: rs.Code,
        };

        return obj;
      });

      setRows(newData);
    } else {
      setRows([]);
    }
  };

  useEffect(() => {
    if (mugshotTypes.length > 0) {
      setRowCount(mugshotTypes.length);
      setXGridData(mugshotTypes);
    } else {
      setRows([]);
    }
  }, [mugshotTypes]);
  useEffect(() => {
    dispatch(getCodeMugshotTypes());
  }, []);

  const handleDelete = async (data) => {
    try {
      dispatch(setCircularLoading(true));
      const service = wsClient?.websocket.service('codemugshottypes');
      service.timeout = 20000;
      const dt = await service.remove(data.id);

      dispatch(notify('Delete Successfully'));
      dispatch(getCodeMugshotTypes());
    } catch (error) {
      dispatch(notify('Deleting Failed. Please try again.', 'error'));
      console.log(error);
    } finally {
      dispatch(setCircularLoading(false));
    }
  };
  const handleEdit = (data) => {
    setFormData({ id: data.id, Code: data.Code, Description: data.Description });
  };
  useEffect(() => {
    const col = fields.map((e) => {
      if (e == 'Action') {
        return {
          field: 'Action',
          headerName: e,
          width: 600,
          height: 550,
          renderCell: (params) => {
            return (
              <>
                <IconButton
                  color="primary"
                  onClick={() => handleDelete(params.row)}
                  aria-label="add an alarm"
                  className="p-2 mr-4">
                  <DeleteOutlineIcon />
                </IconButton>
                {/* <IconButton
                  color="primary"
                  onClick={() => handleEdit(params.row)}
                  aria-label="add an alarm">
                  <EditIcon />
                </IconButton> */}
                {/* <Button startIcon={<DeleteOutlineIcon />}></Button>
                <Button startIcon={<EditIcon />}></Button> */}
              </>
            );
          },
        };
      } else {
        return {
          field: e,
          headerName: e,
          width: 250,
          height: 350,
        };
      }
    });
    setColumns(col);
  }, []);
  const handleInputChange = (e) => {
    const newForm = { ...formData };
    newForm[e.target.name] = e.target.value;
    setFormData(newForm);
  };

  const handleSave = async (e) => {
    try {
      dispatch(setCircularLoading(true));
      const service = wsClient?.websocket.service('codemugshottypes');

      const data = await service.create(formData);

      dispatch(notify('SuccessFully created'));
      dispatch(getCodeMugshotTypes());
      setFormData({});
    } catch (error) {
      dispatch(notify('Saving Failed. make sure Code is unique and max lenght 10.', 'error'));
      console.log(error);
    } finally {
      dispatch(setCircularLoading(false));
    }
  };

  return (
    <div>
      <Card className="card-box m-3" style={{ height: '150px', padding: '10px' }}>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={4}>
            <TextField
              size="small"
              variant="outlined"
              label="Code"
              key="Code"
              placeholder="max len(10) and should be unique"
              name="Code"
              className={classes.input}
              value={formData.Code || ''}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              variant="outlined"
              label="Description"
              key="Description"
              name="Description"
              className={classes.input}
              value={formData.Description || ''}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.select}
              key="internalCode">
              <InputLabel id={'internalCode'}>Internal Code</InputLabel>
              <Select
                label="internal Code"
                className={classes.selectInput}
                labelId="InternalCode"
                value={formData.InternalCode || ''}
                name="InternalCode"
                onChange={handleInputChange}>
                <MenuItem value=""> </MenuItem>

                {['FRONT', 'PROFILE', 'OTHER'].map((code, idx) => (
                  <MenuItem value={code} key={idx}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.actionButton}>
          <Button
            startIcon={<DeleteOutlineIcon />}
            className={classes.button}
            onClick={() => setFormData({})}>
            Clear Form
          </Button>
          {/* {selected.selected && (
            <>
              <IconButton color="secondary" aria-label="add an alarm">
                <RemoveCircleIcon fontSize="large" />
              </IconButton>
              <IconButton color="primary" aria-label="add to shopping cart">
                <EditIcon fontSize="large" />
              </IconButton>
            </>
          )} */}
          <Button
            variant="contained"
            color="primary"
            style={{ width: '120px' }}
            className={classes.button}
            onClick={handleSave}>
            Save
          </Button>
        </div>
      </Card>
      <Card className="card-box m-3">
        <Grid container className={classes.root}>
          <React.Fragment className={classes.paper}>
            <XGrid
              onPageChange={(params) => {
                props.handlePageChange(params);
              }}
              onPageSizeChange={(params) => {
                props.handleChangeRowsPerPage(params);
              }}
              rowsPerPageOptions={[10, 20, 50]}
              pageSize={props.rowsPerPage}
              rowCount={rowCount}
              pagination
              paginationMode="server"
              loading={props.loading}
              rows={rows}
              columns={columns}
            />
          </React.Fragment>
        </Grid>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    wsClient: state.websocket,
    persons: state.persons,
    config: state.config,
    mugshotTypes: state.codes.mugshotTypes,
  };
};

export default connect(mapStateToProps)(Categories);
