import store from '../config/configureStore';
import tableConfig from '../config/table-config-persons.json';

// Person Search

export const FIND_PERSON = 'PERSONSEARCH/FIND_PERSON';
export const OPEN_DETAILS = 'PERSONSEARCH/OPEN_DETAILS';
export const CLOSE_DETAILS = 'PERSONSEARCH/CLOSE_DETAILS';
export const CLEAR_LINEUP_INFO = 'CLEAR_LINEUP_INFO';
export const SET_LINEUP_FORM_DETAILS = 'SET_LINEUP_FORM_DETAILS';
export const SET_LINEUP_PHOTOS = 'SET_LINEUP_PHOTOS';
export const SET_DELETED_PHOTOS = 'SET_DELETED_PHOTOS';

export const CLEAR_RESULTS = 'PERSONSEARCH/CLEAR_RESULTS';
export const SET_LINEUP_FORM_DATA = 'SET_LINEUP_FORM_DATA';
export const SET_FORM_PERSON_PICKERS = 'NCIC/SET_FORM_PERSON_PICKERS';
export const SET_FORM_PERSON_FILTERS = 'NCIC/SET_FORM_PERSON_FILTERS';
export const SET_FORM_PERSON_CHECKBOX = 'PERSONSEARCH/SET_FORM_PERSON_CHECKBOX';
export const SET_FORM_PERSON_LINEUP = 'SET_FORM_PERSON_LINEUP';
export const SET_NCIC_FINISHED_RESPONSES = 'NCIC/SET_NCIC_FINISHED_RESPONSES';
export const SET_NCIC_PENDING_RESPONSES = 'NCIC/SET_NCIC_PENDING_RESPONSES';
export const SET_LINEUP_DATA = 'SET_LINEUP_DATA';
/* New ncic implementation */

export const handleDateChange = (date, dbName, param) => {
  const pickerValues = store.store.getState().LineupSearch.lineupForm.pickers;
  return async (dispatch) => {
    const value = pickerValues[dbName] ? pickerValues[dbName] : { from: null, to: null };
    value[param] = date;
    if (param === 'from' && !value.to) value.to = new Date();
    if (param === 'to' && !value.from) value.from = date;
    if (param === 'single') {
      value.from = date;
      value.to = date;
    }
    const currentPickerValue = { ...pickerValues };
    if (value.from !== null && value.to !== null) {
      currentPickerValue[dbName] = value;
    } else {
      delete currentPickerValue[dbName];
    }
    dispatch({
      type: SET_FORM_PERSON_PICKERS,
      payload: currentPickerValue,
    });
  };
};

export const handleFilterChange = (ev, filter) => {
  const values = store.store.getState().LineupSearch.lineupForm.filters;
  return async (dispatch) => {
    values[filter.dbName] = { type: filter.type, search: ev.target.value };
    dispatch({
      type: SET_FORM_PERSON_FILTERS,
      payload: cleanFilters(values),
    });
  };
};
export const handleRangeFilterChange = (ev, name, filter) => {
  const values = store.store.getState().LineupSearch.lineupForm.filters;
  const search = values[filter.dbName]?.search;

  return async (dispatch) => {
    if (name == 'min') {
      values[filter.dbName] = { type: filter.type, search: { ...search, min: ev.target.value } };
    } else {
      values[filter.dbName] = { type: filter.type, search: { ...search, max: ev.target.value } };
    }

    dispatch({
      type: SET_FORM_PERSON_FILTERS,
      payload: cleanFilters(values),
    });
  };
};

export const handleLineupChange = (ev) => {
  return async (dispatch) => {
    dispatch({
      type: SET_FORM_PERSON_LINEUP,
      payload: ev.target.checked,
    });
  };
};

export const clearLineupForm = () => {
  return async (dispatch) => {
    dispatch({
      type: SET_FORM_PERSON_FILTERS,
      payload: {},
    });
    dispatch({
      type: SET_FORM_PERSON_PICKERS,
      payload: {},
    });
  };
};

export const cleanFilters = (obj) => {
  const result = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (val.search !== '') result[key] = val;
  });
  return result;
};

export const setLineupFormData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LINEUP_FORM_DATA,
      payload: data,
    });
  };
};
export const setLineupData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LINEUP_DATA,
      payload: data,
    });
  };
};
export const setLineupFormDetails = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LINEUP_FORM_DETAILS,
      payload: data,
    });
  };
};
export const setLineupPhotos = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LINEUP_PHOTOS,
      payload: data,
    });
  };
};
export const setDeletedPhotos = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DELETED_PHOTOS,
      payload: data,
    });
  };
};
export const clearLineupInfo = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_LINEUP_INFO });
  };
};

export default function reducer(
  state = {
    // lineup search

    lineupForm: {
      filters: {},
      pickers: {},
      ncic: false,
      lineup: false,
    },
    lineupData: [],
    lineupFormData: {},
    lineupFormDetails: { NumberOfPhoto: 6 },
    deletedPhotos: [],
    lineupPhotos: [],
  },
  action
) {
  switch (action.type) {
    // Person

    case CLEAR_RESULTS:
      return {
        ...state,
        persons: {
          rows: [],
          count: 5000,
          pageNo: 0,
          search: '',
          rowsPerPage: tableConfig.rowsPerPage,
          orderBy: tableConfig.orderBy,
          orderDirection: tableConfig.orderDirection,
          loading: false,
          loaded: false,
          error: false,
        },
      };
    case SET_FORM_PERSON_PICKERS:
      return {
        ...state,
        lineupForm: {
          ...state.lineupForm,
          pickers: action.payload,
        },
      };
    case SET_FORM_PERSON_FILTERS:
      return {
        ...state,
        lineupForm: {
          ...state.lineupForm,
          filters: action.payload,
        },
      };
    case SET_FORM_PERSON_CHECKBOX:
      return {
        ...state,
        lineupForm: {
          ...state.lineupForm,
          ncic: action.payload,
        },
      };
    case SET_FORM_PERSON_LINEUP:
      return {
        ...state,
        lineupForm: {
          ...state.lineupForm,
          lineup: action.payload,
        },
      };
    case SET_LINEUP_FORM_DATA:
      return {
        ...state,
        lineupFormData: {
          ...action.payload,
        },
      };
    case SET_LINEUP_DATA:
      return {
        ...state,
        lineupData: {
          ...action.payload,
        },
      };
    case SET_LINEUP_FORM_DETAILS:
      return {
        ...state,
        lineupFormDetails: {
          ...action.payload,
        },
      };
    case SET_LINEUP_PHOTOS:
      return {
        ...state,
        lineupPhotos: action.payload,
      };
    case SET_DELETED_PHOTOS:
      return {
        ...state,
        deletedPhotos: action.payload,
      };
    case CLEAR_LINEUP_INFO:
      return {
        ...state,
        deletedPhotos: [],
        lineupPhotos: [],
        lineupFormDetails: { NumberOfPhoto: 6 },
        lineupFormData: {},
      };
    default:
      break;
  }
  return state;
}
