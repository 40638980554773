import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet, Font } from '@react-pdf/renderer';
// import DemoImage from '../../../assets/images/avatars/avatar-526x526.png';
import Logo from '../../../assets/images/Retiree.png';
// import Logo from '../../../assets/images/Retiree.png';
import Logo2 from '../../../assets/images/main.png';
import BoldFont from '../../../assets/widgets/Arial/ARIALBD.ttf';
import RegularFont from '../../../assets/widgets/Arial/ARIAL.ttf';
import MediumFont from '../../../assets/widgets/Arial/ArialMdm.ttf';
import offenderImage from '../../../assets/images/WristbadgeImage.png';
import moment from 'moment';
// import moment from 'moment';
Font.register({
  family: 'Arial',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
      fontWeight: 'bold',
    },
    {
      src: MediumFont,
    },
  ],
});
const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Arial',
    fontWeight: 'bold',
  },
  logo: {
    width: 94,
    height: 94,
  },
  logo2: {
    width: 94,
    height: 96,
  },
  textType: {
    fontSize: 24,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  headerLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'left',
    alignItems: 'center',
  },
  headerText: {
    flex: 0.6,
  },
  textMd: {
    fontSize: 20,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  callText: {
    fontSize: 17,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  redText: {
    fontSize: 19,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#ff0000',
    paddingTop: 6,
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  nameText: {
    fontSize: 22,
    textAlign: 'left',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
  imageSide: {
    flex: 0.45,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 80,
  },
  infoSide: {
    flex: 0.55,
    paddingTop: 47,
  },
  offImg: {
    width: 115,
    height: 175,
    objectFit: 'contain',
  },
  object1: {
    fontSize: 15,
    paddingTop: 2,
    fontFamily: 'Arial',
    fontWeight: 'bold',
  },
  personInfo: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  left: {
    width: '50%',
  },
  right: { width: '50%' },
  pair: {
    display: 'flex',
    flexDirection: 'row',
  },
  key: {
    width: '50%',
    textAlign: 'left',
    fontSize: 15,
    fontFamily: 'Arial',
    fontWeight: 'bold',
  },
  value: {
    width: '50%',
    textAlign: 'left',
    fontSize: 13,
    fontFamily: 'Arial',
    fontWeight: 'bold',
  },
  bottomText: {
    fontSize: 12,
    fontFamily: 'Arial',
    fontWeight: 'bold',

    textAlign: 'center',
  },
  bottomRedText: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    color: '#ff0000',
    paddingBottom: 8,
  },
  bottom: {
    textAlign: 'center',
    flex: 0.3,
  },
});

// Create Document Component
const EscalertBadge = ({ personInfo, images }) => {
  let front = images?.filter((img) => img.InternalCode == 'FRONT');
  let defaultFront = front?.filter((img) => img.IsDefault == true);
  if (defaultFront.length == 0) {
    defaultFront = front;
  }
  let profile = images?.filter((img) => img.InternalCode == 'PROFILE');
  let defaultProfile = profile?.filter((img) => img.IsDefault == true);
  if (defaultProfile.length == 0) {
    defaultProfile = profile;
  }

  return (
    <Document>
      <Page size="LETTER" style={{ paddingHorizontal: 36, paddingVertical: 38 }}>
        <View style={{ textAlign: 'center' }}>
          <Text style={styles.textType}>Colorado Department of Corrections</Text>
          <View style={styles.headerLogo}>
            <View style={{ flex: 0.2 }}>
              <Image style={styles.logo2} source={Logo} />
            </View>

            <View style={styles.headerText}>
              <Text
                style={{
                  fontSize: 54,
                  fontFamily: 'Arial',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}>
                ALERT
              </Text>
              <Text style={styles.textMd}>For Law Enforcement Use Only</Text>
              <Text style={styles.textType}> HIGH PROFILE ESCAPEE</Text>
            </View>
            <View style={{ flex: 0.2 }}>
              <Image style={styles.logo} source={Logo2} />
            </View>
          </View>
        </View>
        <View style={styles.info}>
          <View style={styles.imageSide}>
            <Image
              style={styles.offImg}
              source={
                defaultFront[0]
                  ? `data:image/jpeg;base64,${defaultFront[0].FileObject}`
                  : offenderImage
              }
            />
            <Image
              style={styles.offImg}
              source={
                defaultProfile[0]
                  ? `data:image/jpeg;base64,${defaultProfile[0].FileObject}`
                  : offenderImage
              }
            />
          </View>
          <View style={styles.infoSide}>
            <Text style={styles.nameText}>{personInfo.lastname + ', ' + personInfo.firstname}</Text>
            <Text style={styles.object1}>DOC #: {personInfo.ptspersonid}</Text>
            <Text style={styles.object1}>
              D.O.B: {moment(personInfo.born).format('YYYY-MM-DD')}
            </Text>
            <View style={styles.personInfo}>
              <View style={styles.left}>
                <View style={styles.pair}>
                  <Text style={styles.key}>Sex</Text>
                  <Text style={styles.value}>{personInfo.sex}</Text>
                </View>
                <View style={styles.pair}>
                  <Text style={styles.key}>Height</Text>
                  <Text style={styles.value}>{personInfo.height}</Text>
                </View>
                <View style={styles.pair}>
                  <Text style={styles.key}>Eyes</Text>
                  <Text style={styles.value}>{personInfo.eyecolor}</Text>
                </View>
              </View>
              <View style={styles.right}>
                <View style={styles.pair}>
                  <Text style={styles.key}>Ethnicity</Text>
                  <Text style={styles.value}>{personInfo.ethnicity}</Text>
                </View>
                <View style={styles.pair}>
                  <Text style={styles.key}>Weight</Text>
                  <Text style={styles.value}>{personInfo.weight}</Text>
                </View>
                <View style={styles.pair}>
                  <Text style={styles.key}>Hair</Text>
                  <Text style={styles.value}>{personInfo.haircolor}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ marginTop: 100, textAlign: 'center' }}>
          <Text style={styles.callText}>TO REMAIN ANONYMOUS</Text>
          <Text style={styles.callText}>PLEASE CALL:</Text>
          <Text style={styles.callText}>Fugutive Location and Apprehension Group (FLAG)</Text>
          <Text style={styles.callText}>Denver Region</Text>
          <Text style={styles.redText}>Tip Line: (720) 748-5018</Text>
        </View>
        <View
          style={{
            marginTop: 65,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}>
          <View style={styles.bottom}>
            <Text style={styles.bottomRedText}>Crime Stoppers Pueblo</Text>
            <Text style={styles.bottomText}>(719) 542-7867</Text>
          </View>
          <View style={styles.bottom}>
            <Text style={styles.bottomRedText}>Crime Stoppers Denver</Text>
            <Text style={styles.bottomText}>(720) 913-7867 </Text>
          </View>
          <View style={{ textAlign: 'center', flex: 0.4 }}>
            <Text style={styles.bottomRedText}>Crime Stoppers Colorado Springs</Text>
            <Text style={styles.bottomText}>(719) 634-7867</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default EscalertBadge;
