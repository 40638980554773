import React, { Fragment } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import {
  Card,
  Button,
  Grid,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Divider,
  FormControlLabel,
  Tooltip,
  Fab,
  Dialog,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import Camera from '../../components/Camera';

import {
  findPerson,
  handleDataSourceChange,
  setFromDetail,
} from '../../reducers/PersonSearchReducer';
import { MenuItem } from 'react-contextmenu';
import { BlobProvider } from '@react-pdf/renderer';
import EmpCirtbadge from 'components/RecordPdf/EmployeePdf/EmpCirtbadge';
import EmpCntbadge from 'components/RecordPdf/EmployeePdf/EmpCntbadge';
import EmpErtbadge from 'components/RecordPdf/EmployeePdf/EmpErtbadge';
import EmpGrnwrkbadge from 'components/RecordPdf/EmployeePdf/EmpGrnwrkbadge';
import EmpMainbadge from 'components/RecordPdf/EmployeePdf/EmpMainbadge';
import EmpRetireebadge from 'components/RecordPdf/EmployeePdf/EmpRetireebadge';
import EmpVolunteerbadge from 'components/RecordPdf/EmployeePdf/EmpVolunteerbadge';
import CIWorkerBadge from 'components/RecordPdf/OffenderPdf/CIWorkerBadge';
import EscalertBadge from 'components/RecordPdf/OffenderPdf/EscalertBadge';
import FRPRViewBadge from 'components/RecordPdf/OffenderPdf/FRPRViewBadge';
import GatepassBadge from 'components/RecordPdf/OffenderPdf/GatepassBadge';
import MaintenanceBadge from 'components/RecordPdf/OffenderPdf/MaintenanceBadge';
import ParoleBadge from 'components/RecordPdf/OffenderPdf/ParoleBadge';
import MainBadge from 'components/RecordPdf/OffenderPdf/MainBadge';
import WristBandbadge from 'components/RecordPdf/OffenderPdf/WristbandBadge';
import { setCircularLoading } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import moment from 'moment';
import InmateReport from 'components/RecordPdf/OffenderPdf/InmateReport';
import { DATABASE_TYPE } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    display: 'flex',
    minHeight: '100%',
    padding: theme.spacing(2),
    '& > div': {
      heihgt: '100%',
      '&:first-child': {
        width: '55%',
      },
      '&:nth-child(2)': {
        width: '45%',
      },
    },
    '& h3': {
      fontSize: '1.25rem',
    },
  },
  eventList: {
    width: '30%',
  },
  eventsWrap: {
    display: 'flex',
  },
  btnGroup: {
    marginBottom: 10,
    marginLeft: 10,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  eventDetails: {
    width: '70%',
    boxSizing: 'border-box',
    padding: '0 10px 0 10px',
  },
  form: {
    width: '200px',
    textAlign: 'left',
    marginRight: '15px',
  },
  units: {
    paddingLeft: '1em',
  },
  select: {
    width: '180px',
    fontSize: '20px',
    marginRight: '15px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  selectInput: {
    fontSize: '16px',
    padding: '10px',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  imageGrid: {
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  item: {
    paddingLeft: '32px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px',
    },
  },
}));

const typesEmployee = [
  'emp-cirtbadge',
  'emp-cntbadge',
  'emp-ertbadge',
  'emp-mainbadge',
  'emp-retiree',
  'emp-grnwrkbadge',
  'emp-volunteer',
];
const typesOffender = [
  'off-ciwork',
  'off-escalert',
  'off-frprview',
  'off-gatepass',
  'off-mainbadge',
  'off-maintwork',
  'off-parolee',
  'off-wristband',
  'inmate-report',
];
function Person(props) {
  const classes = useStyles();
  const { id } = useParams();
  let history = useHistory();
  const { wsClient } = props;
  const url = useLocation();
  const dispatch = useDispatch();
  const { data } = props.personSearch.personDetails;
  const recordsData = props.personSearch.personDetailsForRecords.data;
  const userType = url.pathname.split('/')[2];
  const [completeSelection, setCompleteSelection] = React.useState(true);
  const [inmatephotos, setInmatePhotos] = React.useState([]);
  const off = userType === 'inmate' ? true : false;
  const recordType = useSelector((state) => state.personSearch.personForm.recordType);
  const [cameraDialog, setCameraDialog] = React.useState(false);
  const [badgeType, setBadgeType] = React.useState(off ? typesOffender[0] : typesEmployee[0]);
  const [imageDetail, setImageDetail] = React.useState({});

  const {
    inmatesViewOnly,
    inmatesEdit,
    inmatesDelete,
    inmatesReports,
    inmatesTakePhoto,
    employeeViewOnly,
    employeeDelete,
    employeeEdit,
    employeeTakePhoto,
    employeeReports,
  } = useSelector((state) => state.Permissions);

  const cameraOpen = (dt = '') => {
    setImageDetail(dt);
    setCameraDialog(true);
  };

  const cameraClose = () => {
    setCameraDialog(false);
  };

  React.useEffect(() => {
    dispatch(setFromDetail(true));
  }, []);

  React.useEffect(() => {
    if (badgeType === 'inmate-report') {
      setCompleteSelection(true);
    }
  }, [badgeType]);

  React.useEffect(() => {
    if (props.wsClient.websocket) {
      const userType = url.pathname.split('/')[2];

      const off = userType === 'inmate' ? 'inmate' : 'employee';
      props.handleDataSourceChange(off);
      props.findPerson(id, off);
    }
  }, [findPerson]);

  let mugshots = [];
  let idPerson = null;
  if (data !== null) {
    mugshots = data.Mugshots !== null && data.Mugshots ? JSON.parse(data.Mugshots) : [];
    idPerson = data.ptspersonid;
  }

  const handleBadgeTypeChange = (e) => {
    setBadgeType(e.target.value);
  };

  const handleIsDefaultChange = async (isChecked, m) => {
    try {
      dispatch(setCircularLoading(true));
      const service = wsClient?.websocket.service('ic-person-photo');

      await service.update(m.ptsImageID, { ...m, IsDefault: isChecked, database: DATABASE_TYPE });

      const userType = url.pathname.split('/')[2];

      const off = userType === 'inmate' ? 'inmate' : 'employee';
      props.handleDataSourceChange(off);
      props.findPerson(id, off);
      dispatch(notify('Successfully Updated'));
    } catch (error) {
      dispatch(notify('Saving Failed. Please try again.', 'error'));
      console.log(error);
    } finally {
      dispatch(setCircularLoading(false));
    }
  };

  const handleDelete = async (e, m) => {
    try {
      dispatch(setCircularLoading(true));
      const service = wsClient?.websocket.service('ic-person-photo');

      await service.remove(m.ptsImageID, { query: { dbType: DATABASE_TYPE } });
      const userType = url.pathname.split('/')[2];

      const off = userType === 'inmate' ? 'inmate' : 'employee';
      dispatch(notify('Successfully Deleted'));
      props.handleDataSourceChange(off);
      props.findPerson(id, off);
    } catch (error) {
      dispatch(notify('Delete Failed. Please try again.', 'error'));
      console.log(error);
    } finally {
      dispatch(setCircularLoading(false));
    }
  };

  const imageInLineup = (img) => {
    if (inmatephotos?.length > 0) {
      const exist = inmatephotos?.filter((lm) => lm.ptsImageID === img.ptsImageID);
      if (exist.length === 0) {
        return false;
      }
      return true;
    }

    return false;
  };

  const hanldePhotoSelect = (img) => {
    setInmatePhotos([...inmatephotos, img]);
  };

  const handleRemoveFromInmatesPhoto = (img) => {
    const filterImage = inmatephotos.filter((i) => i.ptsImageID !== img.ptsImageID);
    setInmatePhotos(filterImage);
  };

  return (
    <Fragment>
      <Card className="card-box m-3">
        <Dialog
          open={completeSelection && badgeType === 'inmate-report' ? true : false}
          onClose={() => setCompleteSelection(false)}
          fullWidth={true}
          sx={{ padding: '20px', textAlign: 'center' }}
          maxWidth={'xl'}>
          <h3 className="m-3">Select Photos </h3>
          <Grid
            container
            spacing={2}
            style={{ padding: '20px', marginTop: '20px', marginBottom: '40px' }}>
            {mugshots.map((m, i) => {
              const image = `data:image/jpeg;base64,${m.FileObject}`;
              return (
                <Grid item xs={4} className="mt-2" key={i}>
                  <div className="d-flex  flex-sm-row align-items-start">
                    <img
                      alt="..."
                      className="card-img-top rounded-sm"
                      src={image}
                      style={{ width: 300 }}
                    />
                    <div>
                      <div className="pl-0 pl-sm-4">
                        <div className="mb-1">
                          <a className="font-size-lg" href="#/" onClick={(e) => e.preventDefault()}>
                            Description: {m.Description}
                          </a>
                        </div>
                        <div>
                          <div className="text-info badge badge-neutral-info">
                            Image Type: {m.ImageType}
                          </div>
                          <br />

                          {/* <div className="text-danger  badge badge-neutral-danger">
                          Mark Type: {m.MarkType}
                        </div> */}
                        </div>
                        <div>
                          <p className="pt-2"> Taken By: {m.CreatedBy}</p>
                          <p>Date: {moment(m.Created).format('MM/DD/YYYY')}</p>
                        </div>
                        <p className="mb-0 mt-1 text-black-50">
                          Is Mark: {m.IsMark ? 'True' : 'False'}
                        </p>
                        {imageInLineup(m) ? (
                          <>
                            <IconButton
                              aria-label="Add"
                              color="primary"
                              size="medium"
                              style={{ float: 'left' }}>
                              <CheckCircleIcon fontSize="large" />
                            </IconButton>
                            <IconButton
                              aria-label="Add"
                              color="primary"
                              size="medium"
                              onClick={() => handleRemoveFromInmatesPhoto(m)}
                              style={{ float: 'left' }}>
                              <RemoveCircleIcon fontSize="large" />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton
                            onClick={() => hanldePhotoSelect(m)}
                            aria-label="Add"
                            color="primary"
                            size="medium"
                            style={{ float: 'left' }}>
                            <AddCircleIcon fontSize="large" />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => setCompleteSelection(false)}
              variant="contained"
              style={{
                padding: '10px',
                width: '120px',
                height: '43px',
                marginBottom: '15px',
                borderRadius: '5px',
              }}
              color="primary">
              Close
            </Button>
            {recordType === 'inmate' && inmatesReports && !inmatesViewOnly && (
              <BlobProvider
                document={<InmateReport personInfo={data} images={inmatephotos} />}
                fileName="lineup.pdf">
                {({ url }) => (
                  <a
                    href={url}
                    target="_blank"
                    style={{
                      width: '125px',
                      textAlign: 'center',
                      backgroundColor: '#0153a3',
                      color: 'whiteSmoke',
                      padding: '8px 12px',
                      marginBottom: '15px',
                      marginLeft: '15px',
                      borderRadius: '5px',
                      height: '41px',
                    }}>
                    Create Report
                  </a>
                )}
              </BlobProvider>
            )}
          </div>
        </Dialog>
        <div className="d-flex py-3 px-3">
          <div className="card-header--title font-size-lg">
            <h4>{`${data?.lastname || ''}${data?.lastname && ', '}${data?.firstname || ''}`}</h4>
            <div className={classes.imageGrid}>
              <div className={classes.imageGrid}>
                <p>ID: {data?.PersonID || ''}</p>
                <p className={classes.item}>
                  DOB: {data?.born ? moment(data?.born).format('MM/DD/YYYY') : ''}
                </p>
              </div>
              <div className={classes.imageGrid}>
                <p className={classes.item}>SEX: {data?.sex || ''}</p>
                <p className={classes.item}>ETHNICITY: {data?.ethnicity || ''}</p>
              </div>
            </div>
          </div>
          <div className="card-header--actions d-flex mr-3">
            {((recordType === 'inmate' && inmatesTakePhoto && !inmatesViewOnly) ||
              (recordType === 'employee' && employeeTakePhoto && !employeeViewOnly)) && (
              <Button
                onClick={cameraOpen}
                variant="contained"
                style={{ height: '41px', padding: '10px 20px', marginRight: '15px' }}
                color="primary">
                Take a photo
              </Button>
            )}
            {recordType === 'inmate' && inmatesReports && !inmatesViewOnly ? (
              <BlobProvider
                document={
                  badgeType === 'off-ciwork' ? (
                    <CIWorkerBadge personInfo={data} images={mugshots} />
                  ) : badgeType === 'off-escalert' ? (
                    <EscalertBadge personInfo={data} images={mugshots} />
                  ) : badgeType === 'off-frprview' ? (
                    <FRPRViewBadge personInfo={data} images={mugshots} />
                  ) : badgeType === 'off-gatepass' ? (
                    <GatepassBadge personInfo={data} images={mugshots} />
                  ) : badgeType === 'off-maintwork' ? (
                    <MaintenanceBadge personInfo={data} images={mugshots} />
                  ) : badgeType === 'off-parolee' ? (
                    <ParoleBadge personInfo={data} images={mugshots} />
                  ) : badgeType === 'off-mainbadge' ? (
                    <MainBadge personInfo={data} images={mugshots} />
                  ) : badgeType === 'off-wristband' ? (
                    <WristBandbadge personInfo={data} images={mugshots} />
                  ) : badgeType === 'inmate-report' ? (
                    <InmateReport personInfo={data} images={inmatephotos} />
                  ) : null
                }
                fileName="lineup.pdf">
                {({ url }) => (
                  <a
                    href={url}
                    target="_blank"
                    style={{
                      width: '125px',
                      textAlign: 'center',
                      backgroundColor: '#0153a3',
                      color: 'whiteSmoke',
                      padding: '8px 12px',
                      marginRight: '15px',
                      borderRadius: '5px',
                      height: '41px',
                    }}>
                    Create Report
                  </a>
                )}
              </BlobProvider>
            ) : (
              recordType === 'employee' &&
              !employeeViewOnly &&
              employeeReports && (
                <BlobProvider
                  document={
                    badgeType === 'emp-cirtbadge' ? (
                      <EmpCirtbadge personInfo={recordsData} images={mugshots} />
                    ) : badgeType === 'emp-cntbadge' ? (
                      <EmpCntbadge personInfo={recordsData} images={mugshots} />
                    ) : badgeType === 'emp-ertbadge' ? (
                      <EmpErtbadge personInfo={recordsData} images={mugshots} />
                    ) : badgeType === 'emp-grnwrkbadge' ? (
                      <EmpGrnwrkbadge personInfo={recordsData} images={mugshots} />
                    ) : badgeType === 'emp-mainbadge' ? (
                      <EmpMainbadge personInfo={recordsData} images={mugshots} />
                    ) : badgeType === 'emp-retiree' ? (
                      <EmpRetireebadge personInfo={recordsData} images={mugshots} />
                    ) : (
                      <EmpVolunteerbadge personInfo={recordsData} images={mugshots} />
                    )
                  }
                  fileName="lineup.pdf">
                  {({ url }) => (
                    <a
                      href={url}
                      target="_blank"
                      style={{
                        width: '125px',
                        textAlign: 'center',
                        backgroundColor: '#0153a3',
                        color: 'whiteSmoke',
                        padding: '8px 12px',
                        marginRight: '15px',
                        borderRadius: '5px',
                        height: '41px',
                      }}>
                      Create Report
                    </a>
                  )}
                </BlobProvider>
              )
            )}
            <FormControl variant="outlined" className={classes.form} size="small">
              <InputLabel htmlFor="outlined-type-native-simple">Type</InputLabel>
              <Select
                small
                value={badgeType}
                onChange={handleBadgeTypeChange}
                label="Type"
                inputProps={{
                  name: 'Type',
                  id: 'outlined-type-native-simple',
                }}>
                <MenuItem value=""></MenuItem>

                {(recordType === 'inmate' ? typesOffender : typesEmployee).map((code) => (
                  <MenuItem value={code} key={code} className={classes.selectInput}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              onClick={() => history.goBack()}
              size="small"
              variant="outlined"
              style={{ height: '37px', padding: '0px 20px' }}
              color="secondary">
              <span style={{ paddingRight: '5px' }}>
                <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-left']} className="text-success" />
              </span>
              <span>Back</span>
            </Button>
          </div>
        </div>
        <Divider light />
        <Grid container spacing={2} className="m-4" style={{ display: 'flex', flexWrap: 'wrap' }}>
          {mugshots.map((m, i) => {
            const image = `data:image/jpeg;base64,${m.FileObject}`;
            return (
              <Grid item xs={12} md={8} lg={6} xl={4} className="mt-2" key={i}>
                <div className="d-flex flex-column flex-sm-row align-items-start">
                  <img
                    alt="..."
                    className="card-img-top rounded-sm"
                    src={image}
                    style={{ width: 300 }}
                  />
                  <div>
                    <div className="pl-0 pl-sm-4">
                      <div className="mb-1">
                        <a className="font-size-lg" href="#/" onClick={(e) => e.preventDefault()}>
                          Description: {m.Description}
                        </a>
                      </div>
                      <div>
                        <div className="text-info badge badge-neutral-info">
                          Image Type: {m.ImageType}
                        </div>
                        <br />

                        {/* <div className="text-danger  badge badge-neutral-danger">
                          Mark Type: {m.MarkType}
                        </div> */}
                      </div>
                      <div>
                        <p className="pt-2"> Taken By: {m.CreatedBy}</p>
                        <p>Date: {moment(m.Created).format('MM/DD/YYYY')}</p>
                      </div>
                      <p className="mb-0 mt-1 text-black-50">
                        Is Mark: {m.IsMark ? 'True' : 'False'}
                      </p>
                      {((recordType === 'inmate' && !inmatesViewOnly) ||
                        (recordType === 'employee' && !employeeViewOnly)) && (
                        <div className="mb-0 mt-2 text-black-50 d-flex">
                          {((recordType === 'inmate' && inmatesEdit) ||
                            (recordType === 'employee' && employeeEdit)) && (
                            <>
                              <FormControlLabel
                                style={{ marginTop: '10px' }}
                                control={
                                  <Checkbox
                                    checked={m.IsDefault}
                                    onChange={(e) => handleIsDefaultChange(e.target.checked, m)}
                                    name="gilad"
                                  />
                                }
                                label="Is Default"
                              />
                              <Tooltip
                                title={props.tooltipText || 'Edit'}
                                style={{ marginTop: '10px', marginLeft: '30px' }}>
                                <Fab size="small" color="secondary" aria-label="edit">
                                  <EditIcon onClick={() => cameraOpen(m)} />
                                </Fab>
                              </Tooltip>
                            </>
                          )}
                          {((recordType === 'inmate' && inmatesDelete) ||
                            (recordType === 'employee' && employeeDelete)) && (
                            <Tooltip
                              title="Delete Photo"
                              style={{ marginTop: '10px', marginLeft: '30px' }}>
                              <Fab
                                size="small"
                                color="secondary"
                                aria-label="edit"
                                onClick={(e) => {
                                  handleDelete(e, m);
                                }}>
                                <DeleteIcon />
                              </Fab>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Divider light />
        <div className="p-3  d-flex justify-content-between">
          <div></div>
          <div>{/* <Button color="primary">Back</Button> */}</div>
        </div>
      </Card>
      <Camera
        findPerson={props.findPerson}
        mugshotTypes={props.mugshotTypes}
        person={idPerson}
        isOpen={cameraDialog}
        imageDetail={imageDetail}
        close={cameraClose}
        open={cameraClose}
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    wsClient: state.websocket,
    personSearch: state.personSearch,
    mugshotTypes: state.codes.mugshotTypes,

    config: state.config,
  };
};

export default connect(mapStateToProps, { findPerson, handleDataSourceChange })(Person);
