import { USER_CLEAR_DATA } from './UserReducer';
import { SNACKBAR_ENQUEUE } from './NotifierReducer';

const getKey = () => new Date().getTime() + Math.random();

const getNotificationOptions = (msgType = 'error') => {
  return {
    key: new Date().getTime() + Math.random(),
    variant: msgType,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  };
};

const getMessage = (message, msgType) => {
  return { message, options: getNotificationOptions(msgType) };
};

const getSnackbar = (notification) => {
  return {
    type: SNACKBAR_ENQUEUE,
    notification,
    key: getKey(),
  };
};

let lastTime = 0;

export const handleError = (error, message) => (dispatch) => {
  const time = new Date().getTime();
  console.log('Handle error', error);
  if (error && error.code === 401) {
    dispatch({ type: USER_CLEAR_DATA });
  } else if (error && error.code === 408 && time - lastTime > 5000) {
    const notification = getMessage('Network error, please check your connection.');
    dispatch(getSnackbar(notification));
    lastTime = time;
  } else if (message) {
    const notification = getMessage(message);
    dispatch(getSnackbar(notification));
  }
};
