import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
// import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
// import SaveIcon from '@material-ui/icons/Save';
// import CloseIcon from '@material-ui/icons/Close';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
// import { getSettings } from '../reducers/ConfigReducer';
import Content from '../Content';
import { getService } from 'reducers/service';
// import Grid from '@mui/material/Grid';
// import Menu from '@material-ui/core/Menu';
// import { formatDate, sortObjArr } from 'utils/functions';
import QuickFilter from 'components/QuickFilter';
// import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from 'components/Tooltip';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { showAddPicUser, showEditPicUser } from 'reducers/DialogsReducer';
import DeleteIcon from '@material-ui/icons/Delete';



const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  edit: {
    padding: theme.spacing(2, 0),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    flexGrow: 1,
  },
  buttons: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    margin: '0 4px 8px',
  },
  list: {
    width: '100%',
    height: 500,
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

// const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const userColumns = [
  { field: 'Username', headerName: 'User Name', width: 250 },
  { field: 'Email', headerName: 'Email', width: 200 },
  { field: 'Phone', headerName: 'Phone Number', width: 250 },
  { field: 'Permissions', headerName: 'Permissions', width: 350 },
];

function PicUsers(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const usersService = getService('pic-users');
  const permissionsService = getService('pic-permissions');
  const usersPermissionsService = getService('pic-users-permissions');


  useEffect(() => {
    fetchPicUserData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataUpdate && 
      (dataUpdate.type === 'add-user' 
        || dataUpdate.type === 'edit-user')) fetchPicUserData();
    // eslint-disable-next-line
  }, [dataUpdate]);

  const fetchPicUserData = async () => {
    setLoaded(false);
    setSelection(null);

    try {
      const users = await usersService.find();
      const perms = await permissionsService.find();
      const usersPerms = await usersPermissionsService.find();

      const processed = process(users, perms, usersPerms);

      setRows(addId(processed));
    } catch(err) {
      props.handleError(err, 'Error, cannot get user data.');
    }

    setLoaded(true);
  }

  const process = (users, perms, usersPerms) => {
    users.forEach(user => {
      const permissions = [];

      usersPerms.forEach(up => {
        if (up.picUserID === user.picUserID) {
          const permObj = perms.find(perm => perm.picPermissionID === up.picPermissionID);
          permissions.push(permObj);
        }
      });

      const reducedPerms = permissions.reduce((result, value, idx) => {
        if (idx) result += ', ';
        result += value.Name;
        return result;
      }, '');

      user.Permissions = reducedPerms;
    });

    return users;
  }

  const addId = (data) => {
    return data.map(row => {
      return {
        ...row,
        id: row.picUserID,
      };
    });
  };

  const addSecUser = () => { props.showAddPicUser() };

  const editSecUser = () => { 
    const item = rows.find(row => row.id === selection);
    if (!item) return;
    props.showEditPicUser(item.picUserID); 
  };

  const DeleteSecUser = async () => {
    if (!window.confirm('Are you sure you want to remove this user?')) return;
    try {
      const item = rows.find(row => row.id === selection);
      if (!item) return;
      await removeUser(item.picUserID);
      fetchPicUserData();
    } catch (err) {
      console.log(err)
    }
  }

  const removeUser = async (userId) => {
    await usersService.remove(userId);
    await usersPermissionsService.update(userId, {});
  }

  return (
    <Content>
      <div className={classes.wrap}>
        <h4> Users List </h4>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.filterWrap}>
            <div className={classes.filterWrap}>
              <QuickFilter
                rows={rows}
                columns={userColumns}
                setFilteredRows={setFilteredRows}
              />
            </div>
            <div className={classes.actions}>
              <Fab onClick={addSecUser} size="small" color="secondary">
                <AddIcon />
              </Fab>
              {selection !== null && (
                <Tooltip title="Edit User">
                  <Fab className="ml-2" size="small" color="secondary" onClick={editSecUser}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
              )}
              {selection !== null && (
                <Tooltip title="Delete User">
                  <Fab className="ml-2" size="small" color="secondary" onClick={DeleteSecUser}>
                    <DeleteIcon />
                  </Fab>
                </Tooltip>
              )}
            </div>
          </div>
        </MuiPickersUtilsProvider>
        <div className={classes.gridWrap}>
          <XGrid
            columns={userColumns}
            rows={filteredRows}
            loading={!loaded}
            rowHeight={38}
            disableMultipleSelection={true}
            showToolbar
            disableColumnFilter
            onSelectionModelChange={(newSelection) => {
              setSelection(newSelection.selectionModel[0]);
            }}
          />
        </div>
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    dataUpdate: state.dataUpdate,
  }
}

export default connect(mapStateToProps, {
  handleError, showSpinner, hideSpinner, showAddPicUser, showEditPicUser,
})(PicUsers);
