import store from '../config/configureStore';
import { updateEvents } from './EventsReducer';
import { updateUnits } from './UnitsReducer';
import { getService } from './service';
import { addEvent } from './DialogsReducer';
import { handleError } from './ErrorReducer';
import { geocodeAddress } from '../utils/mapFunctions';
import { formatDateFrom, formatDateTo } from '../utils/functions';

export const assignUnitToEvent = (ptsUnitID, ptsEventID) => async (dispatch) => {
  const events = store.store.getState().events;
  const eventUnit = getEventUnit(events, ptsUnitID);
  let data;
  if (eventUnit) {
    data = eventUnit;
  } else {
    data = { ptsUnitID };
  }
  dispatch(unitStatusAllChange(data, ptsEventID));
};

export const setUnitStatus = (UnitStatus, ptsUnitID, ptsEventID = null) => async (dispatch) => {
  const events = store.store.getState().events;
  const unit = getEventUnit(events, ptsUnitID, ptsEventID);
  const data = { ...unit, ptsUnitID, UnitStatus, Occurred: null };
  dispatch(unitStatusAllChange(data, ptsEventID));
};

export const getUnitStatus = (ptsUnitID, ptsEventID = null) => {
  const service = getService();
  return service.get({ type: 'unit-status', data: { ptsUnitID, ptsEventID } });
};

export const unitStatusAllChange = (unit, ptsEventID = null, ptsActionID = null) => {
  return async (dispatch) => {
    try {
      await unitStatusAllChangePromise(unit, ptsEventID, ptsActionID);
      dispatch(updateEvents());
      dispatch(updateUnits());
    } catch (error) {
      dispatch(handleError(error, 'Unit status change error.'));
    }
  };
};

export const unitStatusAllChangePromise = (unit, ptsEventID = null, ptsActionID = null) => {
  const service = getService('unit-status-change');
  const { ptsUnitID, Location, Mileage, Notes, Plate, OLN } = unit;
  const Modifiers = (unit.Modifiers && unit.Modifiers.length) ? unit.Modifiers.map(m => m.Code).join() : null;
  const data = {
    ...unit,
    Location: nullIfEmpty(Location),
    Modifiers,
    Mileage: nullIfEmpty(Mileage),
    Notes: nullIfEmpty(Notes),
    Plate: nullIfEmpty(Plate),
    OLN: nullIfEmpty(OLN),
    ptsEventID,
    ptsActionID,
  };
  return service.update(ptsUnitID, data);
};

export const getOutserviceUnits = () => {
  const service = getService();
  return service.get({ type: 'outservice-units' });
};

export const unitInitEvent = (ptsUnitID) => async (dispatch) => {
  try {
    const unitStatus = await getUnitStatus(ptsUnitID);
    const eventData = await formatUnitStatusData(unitStatus);
    dispatch(addEvent(eventData));
  } catch (err) {
    handleError(err, 'Error, Unit Initiated Event not created.')
  }
}

export default function reducer(state = {}, action) {
  return state;
}

// Helper functions

function nullIfEmpty(obj) {
  return obj === undefined || obj === '' ? null : obj;
}

function findUnitInEvent(event, ptsUnitID) {
  return event.assignedUnits.find((unit) => unit.ptsUnitID === parseInt(ptsUnitID));
}

export function getEventUnit(allEvents, ptsUnitID, ptsEventID) {
  let eventUnit = null;
  if (ptsEventID) {
    const event = allEvents.find((event) => event.ptsEventID === ptsEventID);
    eventUnit = findUnitInEvent(event, ptsUnitID);
  }
  if (eventUnit) return eventUnit;
  allEvents.forEach((event) => {
    const eu = findUnitInEvent(event, ptsUnitID);
    if (eu) eventUnit = eu;
  });
  return eventUnit;
}

export const getUnitStatusHistory = async (dateFrom, dateTo, filter) => {
  const service = getService();
  return service.get({
    type: 'unit-status-history',
    data: {
      dateFrom: formatDateFrom(dateFrom),
      dateTo: formatDateTo(dateTo),
      filter,
    },
  });
};

const formatUnitStatusData = async (unitData) => {
  const { Created, Location, Notes, ptsUnitID } = unitData;
  const CallType = store.store.getState().config.options.DefaultUnitInitEventType;
  const data = {
    Event: {
      CallType,
      CallMethod: null,
      RequestedAction: null,
      Description: Location,
      lat: null,
      lng: null,
      CallerPhone: null,
    },
    Notes: Notes ? [{ Comment: Notes }] : [],
    type: 'unit-initiated',
    Unit: {
      ptsUnitID,
      Created,
    }
  };

  const { Mileage, OLN, OLNState, Plate, PlateState, modifiers, Action } = unitData;
  let statusInfo = '';
  const Modifiers = modifiers ? modifiers.map(m => m.Modifier).join(', ') : '';
  if (Action) statusInfo += `Action: ${Action}, \n`;
  if (Mileage) statusInfo += `Mileage: ${Mileage}, \n`;
  if (OLN) statusInfo += `OLN: ${OLN}, \n`;
  if (OLNState) statusInfo += `OLN State: ${OLNState}, \n`;
  if (Plate) statusInfo += `Plate: ${Plate}, \n`;
  if (PlateState) statusInfo += `Plate State: ${PlateState}, \n`;
  if (modifiers) statusInfo += `Modifiers: ${Modifiers}`;
  if (statusInfo) data.Notes.push({ Comment: statusInfo });

  if (Location) {
    const coords = await geocodeAddress(Location);
    if (coords) {
      data.Event.lat = coords.lat;
      data.Event.lng = coords.lng;
    }
  }
  data.Notes.push({ Comment: '' });
  return data;
}