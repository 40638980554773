/**
 * Component will contain all dialogs that could be displayed.
 */
 import React from 'react';
 import { connect } from 'react-redux';
 import AddPicUserDialog from './AddPicUserDialog';
 import EditPicUserDialog from './EditPicUserDialog';
 
 function Dialogs(props) {
   const { isAuthenticated, dialogs } = props;
   const { 
      addPicUser, editPicUser, 
   } = dialogs;
 
   return (
     <>
       {isAuthenticated && (
         <>
          {addPicUser != null && <AddPicUserDialog data={addPicUser}/>}
          {editPicUser != null && <EditPicUserDialog userId={editPicUser}/>}
         </>
       )}
     </>
   );
 }
 
 const mapStateToProps = state => {
   return {
     isAuthenticated: state.user.isAuthenticated,
     dialogs: state.dialogs,
   }
 }
 
 export default connect(mapStateToProps)(Dialogs);
 