import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { XGrid } from '@material-ui/x-grid';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router';
import { setLineupFormData } from 'reducers/LineupsSearchReducer';
import { handleLineupChange } from 'reducers/PersonSearchReducer';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { setCircularLoading } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
const useStyles = makeStyles(() => ({
  root: {
    height: window.innerHeight - 342 + 'px',
  },
}));

function Table(props) {
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const history = useHistory();
  const classes = useStyles();
  const { lineupData, setLineupFormData, handleLineupChange } = props;
  const { wsClient } = props;
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'commodity',
      sort: 'asc',
    },
  ]);
  const dispatch = useDispatch();
  const fields = [
    'ptsLinupID',
    'AgencyID',
    'IncidentID',
    'Created',
    'Notes',
    'NumberOfPhoto',
    'Photos',
    'action',
  ];
  const setXGridData = (result) => {
    if (result.length > 0) {
      const newData = result.map((rs) => {
        let parsedLocalMugshots = rs.Mugshots !== null ? JSON.parse(rs.Mugshots) : null;
        const obj = {
          ...rs,
          Created: rs.Created ? moment(rs.Created).format('MM/DD/YYYY') : '',
          id: rs.ptsLinupID,
        };
        if (parsedLocalMugshots?.length > 0) {
          const sortedImages = parsedLocalMugshots.sort((a, b) => {
            return a.OrderValue - b.OrderValue;
          });
          obj.photo = sortedImages;
        }

        return obj;
      });
      setRows(newData);
    } else {
      setRows([]);
    }
  };

  useEffect(() => {
    if (lineupData.count > 0) {
      setRowCount(lineupData.count);
      setXGridData(lineupData.data);
    } else {
      setRows([]);
    }
  }, [lineupData]);

  React.useEffect(() => {
    const col = fields.map((e) => {
      if (e === 'Photos') {
        return {
          field: 'photo',
          headerName: e,
          width: 450,
          height: 550,
          renderCell: (params) =>
            params?.value?.map((img) => {
              return (
                <img
                  src={'data:image/png;base64, ' + img.FileObject}
                  alt="Photo"
                  width="70"
                  height="70"
                  style={{ borderRadius: '50%', marginLeft: '3px' }}
                />
              );
            }),
        };
      } else if (e === 'action') {
        return {
          field: 'action',
          headerName: e,
          width: 180,

          renderCell: (params) => {
            return (
              <IconButton
                color="primary"
                style={{ textAlign: 'center' }}
                onClick={(e) => handleDelete(e, params.row)}
                aria-label="add an alarm">
                <DeleteOutlineIcon />
              </IconButton>
            );
          },
        };
      } else if (e === 'ptsLinupID') {
        return {
          field: 'ptsLinupID',
          headerName: 'Lineup ID',
          width: 180,
        };
      } else {
        return {
          field: e,
          headerName: e,
          width: 180,
          height: 350,
        };
      }
    });
    setColumns(col);
  }, [wsClient, rows]);

  //   React.useEffect(() => {
  //     setFormData({});
  //   }, []);
  const handleRowClick = ({ row }) => {
    setLineupFormData(row);
    handleLineupChange('lineup');
    history.push(`/lineup/${row.ptsLinupID}`);
  };

  const handleDelete = async (e, data) => {
    e.stopPropagation();
    try {
      dispatch(setCircularLoading(true));
      const service = wsClient?.websocket.service('image-lineups');
      service.timeout = 20000;

      const dt = await service.remove(data.ptsLinupID);

      const newline = rows.filter((lineup) => lineup.ptsLinupID != data.ptsLinupID);

      setRows(newline);
      setRowCount(rowCount - 1);
      dispatch(notify('Delete Successfully'));
    } catch (error) {
      dispatch(notify('Deleting Failed. Please try again.', 'error'));
      console.log(error);
    } finally {
      dispatch(setCircularLoading(false));
    }
  };

  const handleModel = (model) => {
    const values = model.sortModel[0];
    props.handleLineupSearch('', { orderBy: 'l.' + values.field, direction: values.sort });
  };
  return (
    <Grid container className={classes.root}>
      <React.Fragment style={{ height: window.innerHeight - 100 + 'px' }} className={classes.paper}>
        <XGrid
          onPageChange={(params) => {
            props.handlePageChange(params);
          }}
          onPageSizeChange={(params) => {
            props.handleChangeRowsPerPage(params);
          }}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={props.rowsPerPage}
          rowCount={rowCount}
          pagination
          rowHeight={120}
          paginationMode="server"
          loading={props.loading}
          onRowClick={(row) => handleRowClick(row)}
          rows={rows}
          sortModel={sortModel}
          onSortModelChange={(model) => handleModel(model)}
          columns={columns}
        />
      </React.Fragment>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  lineupData: state.LineupSearch.lineupData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, { setLineupFormData, handleLineupChange })(Table);
