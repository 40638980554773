import React from 'react';
// import ReactPDF from '@react-pdf/renderer';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
// import { PDFViewer } from '@react-pdf/renderer';
// import { Button } from '@material-ui/core';
// Create styles
import CheckboxImage from '../../../assets/images/checkbox.png';
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    width: '100%',
  },
  view: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },
  view2: {
    width: 180,

    backgroundColor: '#eee',
    marginTop: 10,
    padding: 3,
    margin: 5,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  description: {
    display: 'flex',
    flexDirection: 'Column',
    padding: 10,
    backgroundColor: '#eee',
  },
  description2: {
    paddingVertical: 3,
    backgroundColor: '#eee',
  },
  text: {
    fontSize: 10,
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
  },
  text2: {
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
    color: '#333',
    paddingLeft: 20,
  },
  text3: {
    fontSize: 7,
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
  },
  text10: {
    padding: 10,
    fontSize: 7,
    fontStyle: 'normal !important',
    fontWeight: 'normal !important',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    justifyContent: 'space-between',
  },
  img: {
    height: '150px',
    objectFit: 'contain',
  },
  img2: {
    width: 35,
    height: 35,
    padding: 10,
  },
  sign: {
    width: '100%',
    marginTop: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
  },
  signField: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
  },
  inputSpace: {
    marginBottom: 5,
    fontSize: 8,
    padding: 5,
    fontWeight: 800,
    width: 100,
    borderBottom: '1px solid black',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imageNumber: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: '#0153a3',
    color: 'white',
    textAlign: 'center',
    paddingTop: 3,
    fontSize: 8,
  },
  imageNumber2: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#0153a3',
    color: 'white',
    textAlign: 'center',
    paddingTop: 5,
    fontSize: 10,
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  imageIcon: {
    margin: 'auto',
    padding: 20,
    textAlign: 'center',
  },
});

// Create Document Component
const PdfView = ({ imageData, formData }) => {
  console.log('from datqa', imageData);
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.description}>
          <Text style={styles.text}>
            Agency ID :<Text style={styles.text2}> {formData?.AgencyID}</Text>
          </Text>
          <Text style={styles.text}>
            Incident ID:<Text style={styles.text2}> {formData?.IncidentID} </Text>
          </Text>
          <Text style={styles.text}>
            LineupID :<Text style={styles.text2}> {formData?.ptsLinupID}</Text>
          </Text>
          <Text style={styles.text}>
            Number Of Photos :<Text style={styles.text2}> {formData?.NumberOfPhoto}</Text>
          </Text>
          <Text style={styles.text}>
            Notes :<Text style={styles.text2}> {formData?.Notes}</Text>
          </Text>
        </View>
        <Text style={styles.text}></Text>
        <View style={styles.view}>
          {imageData?.map((img, index) => (
            <View style={styles.view2} wrap={false}>
              <Image
                style={styles.img}
                source={
                  img.uploadedImage
                    ? '' + img.uploadedImage
                    : `data:image/jpeg;base64,${img.FileObject}`
                }
              />
              <View style={styles.bottom}>
                <Image style={styles.img2} source={CheckboxImage} />
                <Text style={styles.imageNumber}>{index + 1}</Text>
              </View>
            </View>
          ))}
        </View>
        <View style={styles.sign}>
          <View style={styles.signField}>
            <Text style={styles.inputSpace}> </Text>
            <Text style={styles.text3}>Person making identification</Text>
          </View>
          <View style={styles.signField}>
            <Text style={styles.inputSpace}> </Text>
            <Text style={styles.text3}>Employee</Text>
          </View>
          <View style={styles.signField}>
            <Text style={styles.inputSpace}>{new Date().toLocaleString()}</Text>
            <Text style={styles.text3}>Date</Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.description}>
          <Text style={styles.text}>
            Agency ID :<Text style={styles.text2}> {formData?.AgencyID}</Text>
          </Text>
          <Text style={styles.text}>
            Incident ID:<Text style={styles.text2}> {formData?.IncidentID} </Text>
          </Text>
          <Text style={styles.text}>
            Number Of Photos :<Text style={styles.text2}> {formData?.NumberOfPhoto}</Text>
          </Text>
          <Text style={styles.text}>
            Notes :<Text style={styles.text2}> {formData?.Notes}</Text>
          </Text>
        </View>
        <Text style={styles.text}></Text>
        <View style={styles.view}>
          {imageData?.map((img, index) => (
            <View style={styles.view2} wrap={false}>
              <View style={styles.imageIcon}>
                <Text style={styles.imageNumber}>{index + 1}</Text>
              </View>
              <View style={styles.description2}>
                <View style={styles.info}>
                  <Text style={styles.text3}>Name : {img.FullName}</Text>
                </View>
                <View style={styles.info}>
                  <Text style={styles.text3}>ID: {img.ptsImageID}</Text>

                  <Text style={styles.text3}>DOB: {moment(img.Born).format('MM/DD/YYYY')}</Text>
                </View>

                <View style={styles.info}>
                  <Text style={styles.text3}>EyeColor : {img.EyeColor}</Text>
                  <Text style={styles.text3}>Weight : {img.Weight}</Text>
                </View>
                <View style={styles.info}>
                  <Text style={styles.text3}>HairColor : {img.HairColor}</Text>
                  <Text style={styles.text3}>Height : {img.Height}</Text>
                </View>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PdfView;
