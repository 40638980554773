import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Slider from 'react-slick';
import defaultImage from '../../../assets/images/avatars/avatar-526x526.png';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Grid, Box, Typography, Tabs, Tab, Card, CardContent, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core';

import { connect, useDispatch } from 'react-redux';
import { notify } from 'reducers/NotifierReducer';
import {
  setLineupFormData,
  setLineupPhotos,
  setDeletedPhotos,
} from 'reducers/LineupsSearchReducer';
import { useParams } from 'react-router';
import { TabValueContext } from '../../LineupSearch';
import DragAndDrop from './DragAndDrop';
import UploadPhoto from './UploadPhoto';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  bntStyle: {
    float: 'right',
  },
  root: {
    minHeight: window.innerHeight - 385 + 'px',
  },
  input: {
    width: '100%',
  },
  input2: {
    width: '100%',
    padding: '10px',
  },
  downloadBtn: {},
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
var settings = {
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1100,
      settings: { slidesToShow: 1, slidesToScroll: 1 },
    },
  ],
};

const LineupResultView = (props) => {
  const classes = useStyles();
  const {
    persons,
    websocket,
    lineupFormData,
    setLineupFormData,
    setLineupPhotos,
    setDeletedPhotos,
    lineupFormDetails,
    lineupPhotos,
    deletedPhotos,
  } = props;

  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading } = props.persons;

  const [tabValue, setTabValue] = useContext(TabValueContext);
  useEffect(() => {
    if (lineupFormData.ptsLinupID) {
      const images = lineupFormData.photo;
      setLineupPhotos(images);
      setLineupFormData({});
    }
  }, [lineupFormData.ptsLinupID, id]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const hanldeLineupClick = (item, img) => {
    console.log(item, img);
    if (lineupPhotos?.length < lineupFormDetails.NumberOfPhoto) {
      const object = {
        FullName:
          (item.lastname || '') + ', ' + (item.firstname || '') + ' ' + (item.middlename || ''),
        Born: item.born || '',
        Sex: item.sex || '',
        Ethnicity: item.ethnicity || '',
        SSNNumber: item.ssn || '',
        ptspersonid: item.ptspersonid || '',
        Height: item.height || '',
        Weight: item.weight || '',
        ptsImageID: img.ptsImageID || '',
        FileObject: img.FileObject || '',
        EyeColor: item.eyecolor || '',
        HairColor: item.haircolor || '',
      };
      const exist = lineupPhotos.filter((lm) => lm.ptsImageID == img.ptsImageID);
      if (exist.length == 0) {
        const newData = [...lineupPhotos, object];
        newData.forEach((item, index) => (item.OrderValue = index));
        setLineupPhotos(newData);
      }
    } else {
      dispatch(
        notify(`Number of Photo can be Muximun ${lineupFormDetails.NumberOfPhoto}`, 'error')
      );
    }
  };
  const imageInLineup = (img) => {
    if (lineupPhotos?.length > 0) {
      const exist = lineupPhotos?.filter((lm) => lm.ptsImageID == img.ptsImageID);
      if (exist.length == 0) {
        return false;
      }
      return true;
    }

    return false;
  };

  const hanldeLineupRemove = (img) => {
    if (id && lineupPhotos?.length == 1) {
      dispatch(notify('Lineup Can not be Empty', 'error'));
    } else {
      if (img.ptsLinupImageID) {
        setDeletedPhotos([...deletedPhotos, img]);
      }
      const filterLineup = lineupPhotos.filter((lm) => lm.ptsImageID != img.ptsImageID);
      setLineupPhotos(filterLineup);
    }
  };

  const arrangePhoto = (imgs) => {
    const images = JSON.parse(imgs);
    const front = images.filter((img) => img.InternalCode == 'FRONT');
    const notfront = images.filter((img) => img.InternalCode != 'FRONT');

    const img = front.filter((img) => img.IsDefault == true);
    const notimg = front.filter((img) => img.IsDefault == false);
    const newFront = [...img, ...notimg];

    const result = [...newFront, ...notfront];

    return result;
  };
  return (
    <div>
      <div>
        <div>
          <UploadPhoto />
          <DragAndDrop />
          <PerfectScrollbar>
            <div className="p-1">
              <Tabs
                value={tabValue}
                indicatorColor="secondary"
                textColor="secondary"
                variant="fullWidth"
                onChange={handleChange}>
                <Tab label="Result" />
                <Tab label="Lineup" />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Grid container item spacing={2} xs={12} className={classes.root}>
                  {persons.rows.length > 0 && !loading ? (
                    persons?.rows.map((person) => (
                      <Grid item xs={12} lg={4} xl={4} justify="space-around" className="mt-2">
                        <Card className="mb-2" style={{ maxWidth: '80%', height: '500px' }}>
                          {person?.Mugshots && JSON.parse(person?.Mugshots)?.length > 0 ? (
                            <Slider {...settings} style={{ padding: '10px' }}>
                              {person?.Mugshots &&
                                arrangePhoto(person.Mugshots).map((img) => (
                                  <div>
                                    <img
                                      alt="..."
                                      className="card-img-top"
                                      src={`data:image/jpeg;base64,${img.FileObject}`}
                                      style={{
                                        objectFit: 'contain',
                                        width: '100%',
                                        height: '300px',
                                      }}
                                    />
                                    {imageInLineup(img) ? (
                                      <IconButton
                                        aria-label="Add"
                                        color="primary"
                                        size="medium"
                                        style={{ float: 'right' }}>
                                        <CheckCircleIcon fontSize="large" />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() => hanldeLineupClick(person, img)}
                                        aria-label="Add"
                                        color="primary"
                                        size="medium"
                                        style={{ float: 'right' }}>
                                        <AddCircleIcon fontSize="large" />
                                      </IconButton>
                                    )}
                                  </div>
                                ))}
                            </Slider>
                          ) : (
                            <div>
                              <img
                                alt="..."
                                className="card-img-top"
                                src={defaultImage}
                                style={{
                                  objectFit: 'contain',
                                  maxHeight: '300px',
                                  width: '100%',
                                }}
                              />
                            </div>
                          )}
                          <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <h5>{person.lastname + ', ' + person.firstname + ' '}</h5>
                              <h6 style={{ textAlign: 'right' }}>
                                DOB: {moment(person?.born).format('MM/DD/YYYY')}
                              </h6>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>
                                <h6>Eye Color: {person.eyecolor}</h6>
                                <h6>Hair Color: {person.haircolor}</h6>
                              </div>
                              <div>
                                <h6> Weight: {person.weight}</h6>
                                <h6> Height: {person.height}</h6>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : loading ? (
                    <CircularProgress
                      color="primary"
                      size={24}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                      }}
                      className={classes.buttonProgress}
                    />
                  ) : (
                    <p
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                      }}>
                      No Rows
                    </p>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid style={{ minHeight: window.innerHeight - 385 + 'px' }}>
                  {lineupPhotos?.length > 0 ? (
                    <Grid container spacing={2}>
                      {lineupPhotos.map((img, index) => {
                        return (
                          <Grid item xs={4} lg={3} spacing={2}>
                            <Card className="mb-2" style={{ backgroundColor: '#eee' }}>
                              <img
                                alt="..."
                                className="card-img-top"
                                src={
                                  img.uploadedImage
                                    ? img.uploadedImage
                                    : `data:image/jpeg;base64,${img.FileObject}`
                                }
                                style={{
                                  objectFit: 'contain',
                                  width: '100%',
                                  height: '300px',
                                }}
                              />
                              <IconButton
                                onClick={() => hanldeLineupRemove(img)}
                                aria-label="Add"
                                color="primary"
                                size="medium"
                                style={{ float: 'right' }}>
                                <RemoveCircleIcon fontSize="large" />
                              </IconButton>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <p
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                      }}>
                      No Rows
                    </p>
                  )}
                </Grid>
              </TabPanel>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    websocket: state.websocket,
    lineupFormData: state.LineupSearch.lineupFormData,
    lineupFormDetails: state.LineupSearch.lineupFormDetails,
    lineupPhotos: state.LineupSearch.lineupPhotos,
    deletedPhotos: state.LineupSearch.deletedPhotos,
  };
};

export default connect(mapStateToProps, { setLineupFormData, setLineupPhotos, setDeletedPhotos })(
  LineupResultView
);
