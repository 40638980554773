import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import { closeAddPicUser } from 'reducers/DialogsReducer';
import { Button, Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import FormDivider from 'components/FormDivider';
import { getService } from 'reducers/service';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },

  textField: {
    margin: 10,
  },

  checkBox: {
    marginRight: 50,
  },
}));

const AddSecUserDialog = (props) => {
  const classes = useStyles();

  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');

  const [inmatesAccess, setInmatesAccess] = useState(false);
  const [inmatesViewOnly, setInmatesViewOnly] = useState(false);
  const [inmatesAdd, setInmatesAdd] = useState(false);
  const [inmatesEdit, setInmatesEdit] = useState(false);
  const [inmatesDelete, setInmatesDelete] = useState(false);
  const [inmatesTakePhoto, setInmatesTakePhoto] = useState(false);
  const [inmatesCreateLineups, setInmatesCreateLineups] = useState(false);
  const [inmatesViewSsn, setInmatesViewSsn] = useState(false);
  const [inmatesReports, setInmatesReports] = useState(false);

  const [employeeAccess, setEmployeeAccess] = useState(false);
  const [employeeViewOnly, setEmployeeViewOnly] = useState(false);
  const [employeeAdd, setEmployeeAdd] = useState(false);
  const [employeeEdit, setEmployeeEdit] = useState(false);
  const [employeeDelete, setEmployeeDelete] = useState(false);
  const [employeeTakePhoto, setEmployeeTakePhoto] = useState(false);
  const [employeeCreateLineups, setEmployeeCreateLineups] = useState(false);
  const [employeeViewSsn, setEmployeeViewSsn] = useState(false);
  const [employeeReports, setEmployeeReports] = useState(false);

  const [usersAdmin, setUsersAdmin] = useState(false);

  const close = () => {
    props.closeAddPicUser();
  };

  const handleSave = async () => {
    try {
      const userData = {
        Username: userName,
        Email: email,
        Password: password,
        Phone: phone,
      };
      const userService = getService('pic-users');
      const userResult = await userService.create(userData);

      await saveUsersPerms(userResult.picUserID);

      props.notifyDataUpdate({ type: 'add-user', data: { userId: userResult.picUserID } });

      props.closeAddPicUser();
    } catch (err) {
      console.log(err);
    }
  };

  const saveUsersPerms = async (picUserID) => {
    const service = getService('pic-users-permissions');
    const permissionsService = getService('pic-permissions');
    const perms = await permissionsService.find();

    if (inmatesAccess) {
      const picPermission = perms.find((p) => p.Code === 'INMATES_ACCESS');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (inmatesViewOnly) {
      const picPermission = perms.find((p) => p.Code === 'INMATES_VIEW_ONLY');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (inmatesAdd) {
      const picPermission = perms.find((p) => p.Code === 'INMATES_ADD');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (inmatesEdit) {
      const picPermission = perms.find((p) => p.Code === 'INMATES_EDIT');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (inmatesDelete) {
      const picPermission = perms.find((p) => p.Code === 'INMATES_DELETE');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (inmatesTakePhoto) {
      const picPermission = perms.find((p) => p.Code === 'INMATES_TAKE_PHOTO');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (inmatesCreateLineups) {
      const picPermission = perms.find((p) => p.Code === 'INMATES_CREATE_LINEUPS');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (inmatesViewSsn) {
      const picPermission = perms.find((p) => p.Code === 'INMATES_VIEW_SSN');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (inmatesReports) {
      const picPermission = perms.find((p) => p.Code === 'INMATES_REPORTS');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (employeeAccess) {
      const picPermission = perms.find((p) => p.Code === 'EMPLOYEE_ACCESS');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (employeeViewOnly) {
      const picPermission = perms.find((p) => p.Code === 'EMPLOYEE_VIEW_ONLY');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (employeeAdd) {
      const picPermission = perms.find((p) => p.Code === 'EMPLOYEE_ADD');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (employeeEdit) {
      const picPermission = perms.find((p) => p.Code === 'EMPLOYEE_EDIT');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (employeeDelete) {
      const picPermission = perms.find((p) => p.Code === 'EMPLOYEE_DELETE');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (employeeTakePhoto) {
      const picPermission = perms.find((p) => p.Code === 'EMPLOYEE_TAKE_PHOTO');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (employeeCreateLineups) {
      const picPermission = perms.find((p) => p.Code === 'EMPLOYEE_CREATE_LINEUPS');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (employeeViewSsn) {
      const picPermission = perms.find((p) => p.Code === 'EMPLOYEE_VIEW_SSN');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (employeeReports) {
      const picPermission = perms.find((p) => p.Code === 'EMPLOYEE_REPORTS');
      const { picPermissionID } = picPermission;
      service.create({ picUserID, picPermissionID });
    }
    if (usersAdmin) {
      const picPermission = perms.find(p => p.Code === "USERS_ADMIN");
      const { picPermissionID } = picPermission;
      service.create({picUserID, picPermissionID});
    }
  }

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={handleSave}
          disabled={!userName}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderInmatesAccess = () => {
    const handleChange = () => setInmatesAccess(!inmatesAccess);

    return (
      <FormControlLabel
        control={<Checkbox checked={inmatesAccess} onChange={handleChange} />}
        label="Inmates Access"
        className={classes.checkBox}
      />
    );
  };
  const renderInmatesViewOnly = () => {
    const handleChange = () => setInmatesViewOnly(!inmatesViewOnly);

    return (
      <FormControlLabel
        control={<Checkbox checked={inmatesViewOnly} onChange={handleChange} />}
        label="Inmates View Only"
        className={classes.checkBox}
      />
    );
  };
  const renderInmatesAdd = () => {
    const handleChange = () => setInmatesAdd(!inmatesAdd);

    return (
      <FormControlLabel
        control={<Checkbox checked={inmatesAdd} onChange={handleChange} />}
        label="Inmates Add"
        className={classes.checkBox}
      />
    );
  };
  const renderInmatesEdit = () => {
    const handleChange = () => setInmatesEdit(!inmatesEdit);

    return (
      <FormControlLabel
        control={<Checkbox checked={inmatesEdit} onChange={handleChange} />}
        label="Inmates Edit"
        className={classes.checkBox}
      />
    );
  };
  const renderInmatesDelete = () => {
    const handleChange = () => setInmatesDelete(!inmatesDelete);

    return (
      <FormControlLabel
        control={<Checkbox checked={inmatesDelete} onChange={handleChange} />}
        label="Inmates Delete"
        className={classes.checkBox}
      />
    );
  };
  const renderInmatesTakePhoto = () => {
    const handleChange = () => setInmatesTakePhoto(!inmatesTakePhoto);

    return (
      <FormControlLabel
        control={<Checkbox checked={inmatesTakePhoto} onChange={handleChange} />}
        label="Inmates Take Photo"
        className={classes.checkBox}
      />
    );
  };
  const renderInmatesCreateLineups = () => {
    const handleChange = () => setInmatesCreateLineups(!inmatesCreateLineups);

    return (
      <FormControlLabel
        control={<Checkbox checked={inmatesCreateLineups} onChange={handleChange} />}
        label="Inmates Create Lineups"
        className={classes.checkBox}
      />
    );
  };
  const renderInmatesViewSsn = () => {
    const handleChange = () => setInmatesViewSsn(!inmatesViewSsn);

    return (
      <FormControlLabel
        control={<Checkbox checked={inmatesViewSsn} onChange={handleChange} />}
        label="Inmates View SSN"
        className={classes.checkBox}
      />
    );
  };
  const renderInmatesReports = () => {
    const handleChange = () => setInmatesReports(!inmatesReports);

    return (
      <FormControlLabel
        control={<Checkbox checked={inmatesReports} onChange={handleChange} />}
        label="Inmates Reports"
        className={classes.checkBox}
      />
    );
  };

  /************ Employee Permissions *********/

  const renderEmployeeAccess = () => {
    const handleChange = () => setEmployeeAccess(!employeeAccess);

    return (
      <FormControlLabel
        control={<Checkbox checked={employeeAccess} onChange={handleChange} />}
        label="Employee Access"
        className={classes.checkBox}
      />
    );
  };
  const renderEmployeeViewOnly = () => {
    const handleChange = () => setEmployeeViewOnly(!employeeViewOnly);

    return (
      <FormControlLabel
        control={<Checkbox checked={employeeViewOnly} onChange={handleChange} />}
        label="Employee View Only"
        className={classes.checkBox}
      />
    );
  };
  const renderEmployeeAdd = () => {
    const handleChange = () => setEmployeeAdd(!employeeAdd);

    return (
      <FormControlLabel
        control={<Checkbox checked={employeeAdd} onChange={handleChange} />}
        label="Employee Add"
        className={classes.checkBox}
      />
    );
  };
  const renderEmployeeEdit = () => {
    const handleChange = () => setEmployeeEdit(!employeeEdit);

    return (
      <FormControlLabel
        control={<Checkbox checked={employeeEdit} onChange={handleChange} />}
        label="Employee Edit"
        className={classes.checkBox}
      />
    );
  };
  const renderEmployeeDelete = () => {
    const handleChange = () => setEmployeeDelete(!employeeDelete);

    return (
      <FormControlLabel
        control={<Checkbox checked={employeeDelete} onChange={handleChange} />}
        label="Employee Delete"
        className={classes.checkBox}
      />
    );
  };
  const renderEmployeeTakePhoto = () => {
    const handleChange = () => setEmployeeTakePhoto(!employeeTakePhoto);

    return (
      <FormControlLabel
        control={<Checkbox checked={employeeTakePhoto} onChange={handleChange} />}
        label="Employee Take Photo"
        className={classes.checkBox}
      />
    );
  };
  const renderEmployeeCreateLineups = () => {
    const handleChange = () => setEmployeeCreateLineups(!employeeCreateLineups);

    return (
      <FormControlLabel
        control={<Checkbox checked={employeeCreateLineups} onChange={handleChange} />}
        label="Employee Create Lineups"
        className={classes.checkBox}
      />
    );
  };
  const renderEmployeeViewSsn = () => {
    const handleChange = () => setEmployeeViewSsn(!employeeViewSsn);

    return (
      <FormControlLabel
        control={<Checkbox checked={employeeViewSsn} onChange={handleChange} />}
        label="Employee View SSN"
        className={classes.checkBox}
      />
    );
  };
  const renderEmployeeReports = () => {
    const handleChange = () => setEmployeeReports(!employeeReports);

    return (
      <FormControlLabel
        control={<Checkbox checked={employeeReports} onChange={handleChange} />}
        label="Employee Reports"
        className={classes.checkBox}
      />
    )
  }


  const renderUsersAdmin = () => {
    const handleChange = () => setUsersAdmin(!usersAdmin);

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={usersAdmin}
            onChange={handleChange}
          />
        }
        label="Users Administration"
        className={classes.checkBox}
      />
    )
  }
  

  return (
    <Dialog toolbar onClose={close} title="Add User" actions={renderActions()} fullScreen>
      <Grid container className={classes.wrap}>
        <div className={classes.wrap}>
          <FormDivider title="User Information" />
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              label="Username"
              variant="outlined"
              value={userName}
              onChange={(ev) => setUserName(ev.target.value)}
              size="small"
            />
            <TextField
              className={classes.textField}
              label="Email"
              variant="outlined"
              value={email}
              onChange={(ev) => setEmail(ev.target.value)}
              size="small"
            />
            <TextField
              className={classes.textField}
              label="Password"
              variant="outlined"
              value={password}
              onChange={(ev) => setPassword(ev.target.value)}
              size="small"
            />
            <TextField
              className={classes.textField}
              label="Phone"
              variant="outlined"
              value={phone}
              onChange={(ev) => setPhone(ev.target.value)}
              size="small"
            />
          </Grid>
        </div>

        <div className={classes.wrap}>
          <FormDivider title="Inmates related Permissions" />
          <Grid item xs={12}>
            {renderInmatesAccess()}
            {renderInmatesViewOnly()}
            {renderInmatesAdd()}
            {renderInmatesEdit()}
            {renderInmatesDelete()}
            {renderInmatesTakePhoto()}
            {renderInmatesCreateLineups()}
            {renderInmatesViewSsn()}
            {renderInmatesReports()}
          </Grid>
        </div>

        <div className={classes.wrap}>
          <FormDivider title="Employee related Permissions" />
          <Grid item xs={12}>
            {renderEmployeeAccess()}
            {renderEmployeeViewOnly()}
            {renderEmployeeAdd()}
            {renderEmployeeEdit()}
            {renderEmployeeDelete()}
            {renderEmployeeTakePhoto()}
            {renderEmployeeCreateLineups()}
            {renderEmployeeViewSsn()}
            {renderEmployeeReports()}
          </Grid>
        </div>

        <div className={classes.wrap}>
          <FormDivider title="Other Permissions"/>
          <Grid item xs={12}>
            {renderUsersAdmin()}
          </Grid>
        </div>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    //dictionary: state.dictionary
  };
};

export default connect(mapStateToProps, { closeAddPicUser, notifyDataUpdate })(AddSecUserDialog);
