/* eslint-disable import/no-anonymous-default-export */
import { CadLayout } from './layout-blueprints';

// Pages
import Home from './pages/Home';
import Categories from './pages/Categories';
import Person from './pages/Person';
import LineupSearch from 'pages/LineupSearch';
import ReportsPDF from 'pages/ReportsPDF';
import Administration from 'pages/Administration';
import PicUsers from 'pages/Administration/Users';

export const defaultUrl = '/home';

export default [
  { url: '/home', component: Home, layout: CadLayout },
  { url: '/lineup/:id', component: LineupSearch, layout: CadLayout },
  { url: '/image-types', component: Categories, layout: CadLayout },
  { url: '/lineup-search', component: LineupSearch, layout: CadLayout },
  { url: '/report-pdf', component: ReportsPDF, layout: CadLayout },
  { url: '/administration/users', component: PicUsers, layout: CadLayout },
  { url: '/administration', component: Administration, layout: CadLayout },

  { url: '/person/inmate/:id', component: Person, layout: CadLayout },
  { url: '/person/employee/:id', component: Person, layout: CadLayout },
];
