import moment from 'moment';
import htmlForPrinting from './htmlForPrinting';
const locale = window.navigator.userLanguage || window.navigator.language;

moment.locale(locale);
export function formatDate(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.format('L LT');
}

export function formatIfDate(str) {
  if (isValidDate(str)) return formatDate(str);
  return str;
}

export function getTime(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.format('LT');
}

export function getDate(date) {
  if (!date) return;
  const fixedDate = addZtoDate(date);
  const m = moment(fixedDate);
  if (!m.isValid()) return '';
  return m.format('L');
}

export function isValidDate(date) {
  if (typeof date !== 'string' || date.length > 24) return false;
  var dateReg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  if (date.match(dateReg) !== null && moment(date).isValid()) return true;
  return false;
}

/** Fixes issue in some dates in database - adds Z at the end */
function addZtoDate(date) {
  return date + (date.substr(-1) === 'Z' ? '' : 'Z');
}

export function getInitials(status, statusDescription) {
  if (!status) return '';
  if (statusDescription) {
    const words = statusDescription.split(' ');
    if (words.length > 1) {
      return words[0][0] + words[words.length - 1][0];
    }
  }
  const words2 = status.split('-');
  if (words2.length > 1) {
    return words2[0][0] + words2[1][0];
  }
  let nw = '';
  for (let i = 0; i < status.length; i++) {
    const ch = status[i];
    if (i === 0) {
      nw += ch;
    } else if (!isVowel(ch) && nw.length < 2) nw += ch;
  }
  if (nw.length === 2) return nw;
  return status.substr(0, 2);
}

export function isVowel(char) {
  if (char.length === 1) {
    return /[aeiouyAEIOUY]/.test(char);
  }
}

export function groupAndSort(arr, sortBy, groupBy, sortDirection = 'ASC', groupDirection = 'ASC') {
  const groups = arr
    .reduce((res, ev) => {
      const key = ev[groupBy];
      if (res.indexOf(key) === -1) res.push(key);
      return res;
    }, [])
    .sort((a, b) => ((groupDirection === 'ASC' ? a > b : a < b) ? 1 : -1));
  let result = [];
  groups.forEach((val) => {
    const group = arr.filter((obj) => obj[groupBy] === val);
    const sorted = sortObjArr(group, sortBy, sortDirection);
    result = [...result, ...sorted];
  });
  return result;
}

export function sortObjArr(arr, sortBy, sortDirection = 'ASC') {
  if (!Array.isArray(arr)) return arr;
  const newArr = [...arr];
  newArr.sort((a, b) => {
    let valA = a[sortBy];
    let valB = b[sortBy];
    if (isValidDate(valA) && isValidDate(valB)) {
      if (sortDirection === 'ASC') {
        return moment(valB).valueOf() - moment(valA).valueOf();
      } else {
        return moment(valA).valueOf() - moment(valB).valueOf();
      }
    } else {
      if (typeof valA === 'string' && typeof valB === 'string') {
        valA = valA.toLocaleLowerCase();
        valB = valB.toLocaleLowerCase();
      }
      if (sortDirection === 'ASC') {
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      } else {
        return valA > valB ? -1 : valA < valB ? 1 : 0;
      }
    }
  });
  return newArr;
}

export const asyncForEach = async (array, callback) => {
  if (!array) return;
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

/** Used in processing input phone number to format (123) 123-4567 ext 890 */
export const processInputPhoneNo = (value) => {
  const maxLength = 24;
  let digits = '';
  value.split('').forEach((ch) => {
    const reg = /^\d+$/;
    if (ch.match(reg)) digits += ch;
  });
  const digitsArr = digits.split('');
  const len = digitsArr.length;
  len > 0 && digitsArr.splice(0, 0, '(');
  len > 3 && digitsArr.splice(4, 0, ') ');
  len > 6 && digitsArr.splice(8, 0, '-');
  len > 10 && digitsArr.splice(13, 0, ' ext ');
  const output = digitsArr.join('').substr(0, maxLength);
  return output;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const areObjEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));

export const tableComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const printRef = (ref, title) => {
  const content = ref.current;
  let html = htmlForPrinting(content.innerHTML, `${title}`);
  const pri = document.getElementById("print-iframe").contentWindow;
  pri.document.open();
  pri.document.write(html);
  pri.document.close();
  pri.focus();
  pri.print();
}

export const getTableComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => tableComparator(a, b, orderBy)
    : (a, b) => -tableComparator(a, b, orderBy);
};

export const tableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

/**
 * The insertText() method changes the content of a string by removing a range of
 * characters and/or adding new characters.
 *
 * @this {String}
 * @param {string} text to modify.
 * @param {number} position Index at which to start changing the string.
 * @param {number} delCount An integer indicating the number of old chars to remove.
 * @param {string} newSubStr The String that is spliced in.
 * @return {string} A new string with the spliced substring.
 */
export const insertText = (string, position, newSubStr) => {
  return string.slice(0, position) + newSubStr + string.slice(position);
};

export const getExcerpt = (text, length = 60) => {
  text = text.replace(/\n/g, ' ');
  if (text.length > length) {
    text = text.substr(0, length) + '...';
  }
  return text;
};

/**
 * Convert integer number to byte string
 * @param {*} no - number to convert
 * @param {*} pad number of total string length e.g. (1, 4) = "0001" [default 8]
 */
export const decBin = (no, pad = 8) => {
  if (no < 0) {
    no = 0xffffffff + no + 1;
  }
  return parseInt(no, 10).toString(2).padStart(pad, '0');
};

export const formatDateFrom = (date) => {
  return moment(date).format('YYYY-MM-DD') + ' 00:00:00.0';
};

export const formatDateTo = (date) => {
  return moment(date).format('YYYY-MM-DD') + ' 23:59:59.0';
};

/**
 * returns array of filtered results - designed to work witgh DataGrid or XGrid widgets
 *
 * @param {string} filter e.g 'tango'
 * @param {array} data to be filtered
 * @param {array} columns - column definitions
 */
export const filterResults = (filter, arr, columns) => {
  filter = filter.toLocaleLowerCase();
  const result = [];
  arr.forEach((row) => {
    let match = false;
    for (let i = 0; i < columns.length; i++) {
      const col = columns[i].field;
      let val = row[col];
      if (typeof val === 'number') val = '' + val;
      if (typeof val === 'string') {
        if (val.toLocaleLowerCase().indexOf(filter) !== -1) {
          match = true;
          break;
        }
      }
    }
    match && result.push(row);
  });
  return result;
};

/**
 * returns array of filtered results - simple definition
 *
 * @param {string} filter e.g 'tango'
 * @param {array} data to be filtered
 * @param {array} columns - array of names of ['name', 'ptsPersonID']
 */
export const filterArr = (filter, arr, columns) => {
  filter = filter.toLocaleLowerCase();
  const result = [];
  arr.forEach((row) => {
    let match = false;
    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      let val = row[col];
      if (typeof val === 'number') val = '' + val;
      if (typeof val === 'string') {
        if (val.toLocaleLowerCase().indexOf(filter) !== -1) {
          match = true;
          break;
        }
      }
    }
    match && result.push(row);
  });
  return result;
};

/* Used in styling flex forms */
export function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 8px`,
  };
}

/* Reorder list used in drag and drop ordered list */
export function reorderDnd(arr, res) {
  if (!arr) return;
  const from = res.source.index;
  const to = res.destination.index;
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
}

export function removeFromArr(arr, val) {
  const idx = arr.indexOf(val);
  arr.splice(idx, 1);
  return arr;
}
