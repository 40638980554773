import store from '../config/configureStore';
import { setUserAuthenticated, setUserData } from './UserReducer';

export const SET_CODE_SEX = 'INFORMIX_CODE/SET_CODE_SEX';
export const SET_CODE_RACE = 'INFORMIX_CODE/SET_CODE_RACE';
export const SET_CODE_STATE = 'INFORMIX_CODE/SET_CODE_STATE';
export const SET_CODE_VEHICLE_MAKES = 'INFORMIX_CODE/SET_CODE_VEHICLE_MAKES';
export const SET_CODE_VEHICLE_MODELS = 'INFORMIX_CODE/SET_CODE_VEHICLE_MODELS';
export const SET_CODE_FIREARM_CALIBERS = 'INFORMIX_CODE/SET_CODE_FIREARM_CALIBERS';
export const SET_CODE_ITEM_MAKES = 'INFORMIX_CODE/SET_CODE_ITEM_MAKES';
export const SET_CODE_ITEM_MODELS = 'INFORMIX_CODE/SET_CODE_ITEM_MODELS';
export const SET_CODE_PLATE_TYPES = 'INFORMIX_CODE/SET_CODE_PLATE_TYPES';
export const SET_CODE_COLORS = 'INFORMIX_CODE/SET_CODE_COLORS';
export const SET_CODE_HAIRCOLOR = 'INFORMIX_CODE/SET_CODE_HAIRCOLOR';
export const SET_CODE_EYECOLOR = 'INFORMIX_CODE/SET_CODE_EYECOLOR';
export const SET_CODE_EVENT_TYPES = 'INFORMIX_CODE/SET_CODE_EVENT_TYPES';
export const SET_CODE_MUGSHOT_TYPES = 'INFORMIX_CODE/SET_CODE_MUGSHOT_TYPES';
export const SET_CODE_HAIR_LENGTH = 'INFORMIX_CODE/SET_CODE_HAIR_LENGTH';
export const SET_CODE_ETHNICITY = 'INFORMIX_CODE/SET_CODE_ETHNICITY';
export const SET_CODE_HANDED = 'INFORMIX_CODE/SET_CODE_HANDED';

const databaseName = process.env.REACT_APP_DB_CONFIG;
const codeServiceName = process.env.REACT_APP_CODE_SERVICE;
const sexTable = process.env.REACT_APP_SEX_TABLE;
const cadTypesServiceName = process.env.REACT_APP_CAD_TYPES_SERVICE;
const raceServiceName = process.env.REACT_APP_RACE_SERVICE;
const statesServiceName = process.env.REACT_APP_STATES_SERVICE;
const vehicleMakesServiceName = process.env.REACT_APP_VEHICLE_MAKES_SERVICE;
const vehicleMakesModelServiceName = process.env.REACT_APP_VEHICLE_MAKES_MODEL_SERVICE;
const firearmServiceName = process.env.REACT_APP_FIREARM_SERVICE;
const itemMakesServiceName = process.env.REACT_APP_ITEM_MAKES_SERVICE;
const itemModelServiceName = process.env.REACT_APP_ITEM_MODEL_SERVICE;
const plateTypeServiceName = process.env.REACT_APP_PLATE_TYPE_SERVICE;
const colorServiceName = process.env.REACT_APP_COLOR_SERVICE;
const mugshotServiceName = process.env.REACT_APP_MUGSHOT_SERVICE;
const hairTable = process.env.REACT_APP_HAIR_TABLE;
const eyesTable = process.env.REACT_APP_EYES_TABLE;
const hairLengthServiceName = process.env.REACT_APP_HAIR_LENGTH_SERVICE;
const ethnicTable = process.env.REACT_APP_ETHINIC_TABLE;

const codeService = () => {
  try {
    const client = store.store.getState().websocket.websocket;
    const service = client.service(codeServiceName);
    return service;
  } catch (err) {
    console.log(err);
  }
};

export const getInformixCodeSex = () => {
  return async (dispatch) => {
    try {
      const service = codeService();
      service.timeout = 20000;
      const codes = await service.find({ query: { dbName: sexTable } });
      dispatch({ type: SET_CODE_SEX, payload: codes });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeHanded = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = codeService();
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_HANDED, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};
export const getInformixCodeEventType = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(cadTypesServiceName);
      service.timeout = 20000;
      const codes = await service.find();
      dispatch({ type: SET_CODE_EVENT_TYPES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeRace = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(raceServiceName);
      const codes = await service.find({
        query: {
          IsDeleted: false,
        },
      });
      service.timeout = 20000;
      dispatch({ type: SET_CODE_RACE, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeState = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(statesServiceName);
      service.timeout = 20000;
      const states = await service.find({
        query: {
          $limit: 100,
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_STATE, payload: states.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeVehicleMakes = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(vehicleMakesServiceName);
      service.timeout = 20000;
      const makes = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_VEHICLE_MAKES, payload: makes.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeVehicleMakesModels = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(vehicleMakesModelServiceName);
      service.timeout = 20000;
      const models = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_VEHICLE_MODELS, payload: models.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeFirearmCaliber = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(firearmServiceName);
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_FIREARM_CALIBERS, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeItemMake = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(itemMakesServiceName);
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_ITEM_MAKES, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeItemModel = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(itemModelServiceName);
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_ITEM_MODELS, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodePlateTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(plateTypeServiceName);
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_PLATE_TYPES, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeColors = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(colorServiceName);
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
        },
      });
      let _codes = { default: {}, categories: {}, units: {}, events: {} };
      for (let i = 0; i < codes.data.length; i++) {
        switch (codes.data[i].Category) {
          case 'DEFAULT':
            _codes.default[codes.data[i]['ColorKey']] = codes.data[i]['ColorValue'];
            break;
          case 'ACTION_CATEGORY':
            _codes.categories[codes.data[i]['ColorKey']] = codes.data[i]['ColorValue'];
            break;
          case 'EVENT':
            _codes.events[codes.data[i]['ColorKey']] = codes.data[i]['ColorValue'];
            break;
          case 'UNIT_ACTION':
            _codes.units[codes.data[i]['ColorKey']] = codes.data[i]['ColorValue'];
            break;
          default:
            console.log(`there is no handler for ${codes.data[i].Category} `);
        }
      }
      dispatch({ type: SET_CODE_COLORS, payload: _codes });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeMugshotTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(mugshotServiceName);
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_MUGSHOT_TYPES, payload: data.data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getInformixCodeHairColor = () => {
  return async (dispatch) => {
    try {
      const service = codeService();
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
          dbName: hairTable,
        },
      });
      dispatch({ type: SET_CODE_HAIRCOLOR, payload: data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getInformixCodeEyeColor = () => {
  return async (dispatch) => {
    try {
      const service = codeService();
      service.timeout = 20000;
      const data = await service.find({
        query: {
          $limit: 1000,
          IsDeleted: false,
          dbName: eyesTable,
        },
      });
      dispatch({ type: SET_CODE_EYECOLOR, payload: data });
    } catch (error) {
      if (error.code === 401) {
        dispatch(setUserAuthenticated(false));
        dispatch(setUserData(null));
      } else {
        console.log(error);
      }
    }
  };
};
export const getInformixCodeHairLength = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service(hairLengthServiceName);
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      console.log(codes);
      dispatch({ type: SET_CODE_HAIR_LENGTH, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const getInformixCodeEthnicity = () => {
  return async (dispatch) => {
    try {
      const service = codeService();
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          dbName: ethnicTable,
        },
      });
      dispatch({ type: SET_CODE_ETHNICITY, payload: codes });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};
export default function reducer(
  state = {
    colorsCodes: { default: {}, categories: {}, units: {}, events: {} },
    informixSexCodes: [],
    informixRaceCodes: [],
    informixHairColorCodes: [],
    informixHairLengthCodes: [],
    informixEthnicityCodes: [],
    informixHandedCodes: [],
    informixEyeColorCodes: [],
    informixStatesCodes: [],
    informixVehicleMakesCodes: [],
    informixVehicleModelsCodes: [],
    informixFirearmCaliberCodes: [],
    informixItemMakesCodes: [],
    informixItemModelsCodes: [],
    informixPlateTypesCodes: [],
    informixEventTypeCodes: [],
    informixMugshotTypes: [
      { Code: 'FACEFRONT' },
      { Code: 'FACEPROF' },
      { Code: 'OTHER' },
      { Code: 'PROFL' },
      { Code: 'PROFR' },
    ],
  },
  action
) {
  switch (action.type) {
    case SET_CODE_SEX: {
      return {
        ...state,
        informixSexCodes: action.payload,
      };
    }
    case SET_CODE_RACE:
      return {
        ...state,
        informixRaceCodes: action.payload,
      };
    case SET_CODE_STATE:
      return {
        ...state,
        informixStatesCodes: action.payload,
      };
    case SET_CODE_VEHICLE_MAKES:
      return {
        ...state,
        informixVehicleMakesCodes: action.payload,
      };
    case SET_CODE_ETHNICITY:
      return {
        ...state,
        informixEthnicityCodes: action.payload,
      };
    case SET_CODE_HAIR_LENGTH:
      return {
        ...state,
        informixHairLengthCodes: action.payload,
      };
    case SET_CODE_HANDED:
      return {
        ...state,
        informixHandedCodes: action.payload,
      };
    case SET_CODE_VEHICLE_MODELS:
      return {
        ...state,
        informixVehicleModelsCodes: action.payload,
      };
    case SET_CODE_FIREARM_CALIBERS:
      return {
        ...state,
        informixFirearmCaliberCodes: action.payload,
      };
    case SET_CODE_ITEM_MAKES:
      return {
        ...state,
        informixItemMakesCodes: action.payload,
      };
    case SET_CODE_ITEM_MODELS:
      return {
        ...state,
        informixItemModelsCodes: action.payload,
      };

    case SET_CODE_PLATE_TYPES:
      return {
        ...state,
        informixPlateTypesCodes: action.payload,
      };
    case SET_CODE_COLORS:
      return {
        ...state,
        informixColorsCodes: action.payload,
      };
    case SET_CODE_EVENT_TYPES:
      return {
        ...state,
        informixEventTypeCodes: action.payload,
      };
    case SET_CODE_MUGSHOT_TYPES:
      return {
        ...state,
        informixMugshotTypes: action.payload,
      };
    case SET_CODE_HAIRCOLOR:
      return {
        ...state,
        informixHairColorCodes: action.payload,
      };
    case SET_CODE_EYECOLOR:
      return {
        ...state,
        informixEyeColorCodes: action.payload,
      };
    default:
      break;
  }
  return state;
}
