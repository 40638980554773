import React from 'react';

import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';

import Logo from '../../../assets/images/Retiree.png';

import BoldFont from '../../../assets/widgets/Arial/ARIALBD.ttf';
import RegularFont from '../../../assets/widgets/Arial/ARIAL.ttf';
import MediumFont from '../../../assets/widgets/Arial/ArialMdm.ttf';

// import moment from 'moment';
Font.register({
  family: 'Arial',
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: BoldFont,
      fontWeight: 'bold',
    },
    {
      src: MediumFont,
    },
  ],
});
const styles = StyleSheet.create({
  card: {
    width: '100%',
    height: '100%',
    padding: 8,
    textAlign: 'center',
    border: '1px solid #999999',
  },

  logo: {
    width: 57,
    height: 58,
    marginRight: 2,
  },

  content: {
    height: '100%',
    textAlign: 'center',
  },

  number: {
    fontSize: 24,

    textAlign: 'center',
    fontFamily: 'Arial',
    fontWeight: 'bold',
  },
  dept: {
    fontSize: 22,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    paddingBottom: 6,
  },
  greenText: {
    fontSize: 11,
    paddingTop: 6,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    color: '#118a11',
  },
  work: {
    fontSize: 18,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    color: '#2c4d8f',
    paddingBottom: 6,
  },
});

// Create Document Component
const EmpCntbadge = ({ formData }) => {
  const number = [];
  for (let i = formData.from || 1; i <= (formData.to || 1); i++) {
    number.push(i);
  }

  return (
    <Document>
      {number.map((i) => (
        <Page size="ID1" orientation="portrait" wrap={false}>
          <View style={styles.card} wrap={false}>
            <View style={styles.content}>
              <Image style={styles.logo} source={Logo} />
              <View style={styles.text}>
                <Text style={styles.dept}>Colorado</Text>
                <Text style={styles.dept}>Dept of</Text>
                <Text style={styles.dept}>Corrections</Text>
                <Text style={styles.work}>HQ-OHR</Text>
                <Text style={styles.number}>Badge {i}</Text>
                <Text style={styles.greenText}>NO ESCORT REQUIRED</Text>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default EmpCntbadge;
